import { CSSProperties, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Box } from "@mui/material";
import { useParams } from "react-router-dom";
import { dateFnsLocalizer, View } from "react-big-calendar";
import format from "date-fns/format";
import parse from "date-fns/parse";
import startOfWeek from "date-fns/startOfWeek";
import getDay from "date-fns/getDay";
import { EventNotice } from "protogen/calendar_pb";
import CalendarComponent from "./CalendarComponent";
import { CalendarFiltersDelegate } from "./FiltersDelegate";
import AddEventDialog from "./AddEventDialog";
import { CalendarFilter, CalendarSettings } from "./CustomCalendarSettings";
import EditEventDialog from "./EditEventDialog";
import ViewEventDialog from "./ViewEventDialog";
import { EphemeralEvent } from "types/calendars";
import LoadingOverlay from "./LoadingOverlay";
import { useRefreshCalendars } from "../../services/calendar";
import useDateParams from "components/hooks/calendar/useDateParams";
import useIsMobile from "../hooks/useIsMobile";

const LOCALES = {
  "en-US": require("date-fns/locale/en-US"),
};

interface Props {
  events: EventNotice[];
  accountType: "advisor" | "member";
  familyRef?: string;
  embeddedMode?: boolean; // Minimizes toolbar.
  // more general fetcher?
  refreshEvents?: () => Promise<void>;
  // filters - maybe expand to apply filters?
  calendarFilters?: CalendarFilter[];
  setEnabledFilters?: (filters: CalendarFilter[]) => void;
  getEventStyles?: (event: EventNotice) => CSSProperties | null;
  handleNavigate?: (newDate: Date) => Promise<void>;
  loading?: boolean;
}

export default ({
  events,
  accountType,
  familyRef,
  embeddedMode = false,
  refreshEvents,
  calendarFilters,
  setEnabledFilters,
  getEventStyles,
  handleNavigate,
  loading = false,
}: Props) => {
  let params = useParams();
  const location = useLocation();
  const isMobile = useIsMobile();
  const queryParams = new URLSearchParams(location.search);
  const defaultDate = useDateParams();
  const { request: refreshRequest } = useRefreshCalendars();
  const [refreshing, setRefreshing] = useState(false);
  const viewFromParams: View = (queryParams.get("view") || "week") as View;
  const [hasLoadedEventRef, setHasLoadedEventRef] = useState(false);
  const [menuAnchorPosition, setMenuAnchorPosition] = useState<{
    top: number;
    left: number;
  } | null>(null);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<EventNotice | undefined>(
    undefined,
  );
  const [initialEvent, setInitialEvent] = useState<EphemeralEvent | undefined>(
    undefined,
  );
  const localizer = dateFnsLocalizer({
    format,
    parse,
    startOfWeek,
    getDay,
    locales: LOCALES,
  });
  const handleNewEvent = () => {
    setInitialEvent({ familyRef });
    setAddModalOpen(true);
  };
  const handleRefresh = async () => {
    setRefreshing(true);
    await refreshRequest({
      familyRef: familyRef || "",
    });
    await refreshEvents?.();
    setRefreshing(false);
  };
  const handleSelectSlot = ({ start, end }: { start: Date; end: Date }) => {
    const isAllDay =
      start.getHours() === 0 &&
      start.getMinutes() === 0 &&
      end.getHours() === 0 &&
      end.getMinutes() === 0;
    setInitialEvent({
      allDay: isAllDay,
      startDate: start,
      endDate: isAllDay ? new Date(end.setDate(end.getDate() - 1)) : end,
      familyRef,
    });
    setAddModalOpen(true);
  };
  const updatePath = (path: string) => {
    const currentUrl = window.location.href;
    const url = new URL(currentUrl);
    url.pathname = path;
    window.history.pushState({}, "", url.toString());
  };

  const handleSelectEvent = (event: EventNotice) => {
    setSelectedEvent(event);
    setViewModalOpen(true);
    updatePath(`calendar/${event.eventRef}`);
  };

  const onEnableEdit = () => {
    setViewModalOpen(false);
    setEditModalOpen(true);
  };
  const handleSettingsOpen = (event: React.MouseEvent<HTMLElement>) => {
    const targetRect = event.currentTarget.getBoundingClientRect();
    setMenuAnchorPosition({
      // Flipped because we want to anchor to the bottom right.
      top: targetRect.bottom,
      left: targetRect.left,
    });
  };
  const handleSettingsClose = () => {
    setMenuAnchorPosition(null);
  };

  const selectEvent = (eventRef: string) => {
    if (!hasLoadedEventRef && events.length > 0) {
      let event = events.find((e) => e.eventRef === eventRef);
      if (!event) {
        // This is a Faye event and we have not loaded it yet.
        // The view modal will fetch the individual event.
        event = new EventNotice({ eventRef: eventRef });
      }
      if (event) {
        setHasLoadedEventRef(true);
        setViewModalOpen(true);
        setSelectedEvent(event);
      }
    }
  };

  useEffect(() => {
    if (params.eventRef) {
      selectEvent(params.eventRef);
    }
  }, [params.eventRef, events]);

  return (
    <Box sx={{ position: "relative", height: "100%", width: "100%" }}>
      <CalendarComponent
        events={events}
        embeddedMode={embeddedMode}
        localizer={localizer}
        handleSelectEvent={handleSelectEvent}
        handleNewEvent={handleNewEvent}
        handleRefresh={handleRefresh}
        handleNavigate={handleNavigate}
        handleSelectSlot={handleSelectSlot}
        getEventStyles={getEventStyles}
        filtersDelegate={
          calendarFilters?.length ? (
            <CalendarFiltersDelegate onClick={handleSettingsOpen} />
          ) : null
        }
        defaultDate={defaultDate}
        defaultView={isMobile ? "agenda" : viewFromParams}
      />
      <CalendarSettings
        menuOpen={!!menuAnchorPosition}
        handleClose={handleSettingsClose}
        disabled={false}
        calendarFilters={calendarFilters}
        setEnabledFilters={setEnabledFilters}
        anchorPos={menuAnchorPosition}
      />
      {initialEvent && (
        <AddEventDialog
          familyRef={familyRef}
          selectFamily={accountType === "advisor" && !familyRef}
          open={addModalOpen}
          onClose={() => {
            setAddModalOpen(false);
            setInitialEvent(undefined);
          }}
          onCreated={async () => {
            setAddModalOpen(false);
            await refreshEvents?.();
          }}
          initialFormData={initialEvent}
          accountType={accountType}
        />
      )}
      <ViewEventDialog
        open={viewModalOpen}
        onClose={async () => {
          setViewModalOpen(false);
          window.history.back();
        }}
        primaryAction={onEnableEdit}
        eventRef={selectedEvent?.eventRef || ""}
        accountType={accountType}
        eventNotice={selectedEvent}
        onDelete={async () => {
          await refreshEvents?.();
        }}
      />
      <EditEventDialog
        open={editModalOpen}
        onClose={() => {
          setEditModalOpen(false);
          window.history.back();
        }}
        onEdited={async () => {
          setEditModalOpen(false);
          await refreshEvents?.();
        }}
        eventRef={selectedEvent?.eventRef || ""}
        accountType={accountType}
      />
      {(loading || refreshing) && <LoadingOverlay></LoadingOverlay>}
    </Box>
  );
};
