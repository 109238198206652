import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import FlexPage from "../components/layout/FlexPage";
import { useListEvents } from "../services/calendar";
import { ListEventsRequest } from "protogen/calendar_service_pb";
import { defaultParametersV2, CalendarParametersV2 } from "types/calendars";
import { useListAdvisorFamilies } from "services/advisor";
import { EventNotice, EventNotice_NoticeType } from "protogen/calendar_pb";
import FayeCalendar from "components/calendar/FayeCalendar";
import { createColorMap, defaultColor } from "components/calendar/utils";
import useIsMobile from "components/hooks/useIsMobile";
import { AdvisorCalendarTodos } from "components/calendar/CalendarTodos";
import { Family } from "protogen/advisors_service_pb";
import useDateParams from "components/hooks/calendar/useDateParams";

const ADVISOR_CALENDAR = "_advisor-events";

export default ({}: { eventRef?: string }) => {
  const isMobile = useIsMobile();
  const defaultDate = useDateParams();
  const [events, setEvents] = useState<EventNotice[]>([]);
  const [params, setParams] = useState<CalendarParametersV2>({
    ...defaultParametersV2(),
  });
  const [filteredFamilies, setFilteredFamilies] = useState<Set<string> | null>(
    null,
  );
  const { data: familiesData, request: familiesRequest } =
    useListAdvisorFamilies();

  const { request: eventsRequest, loading } = useListEvents();
  const [familyColorMap, setFamilyColorMap] = useState<Record<
    string,
    [string, string]
  > | null>(null);
  const onLoad = async () => {
    const families = (await familiesRequest())?.families || [];
    if (!familyColorMap) {
      setFamilyColorMap(createColorMap(families.map((f) => f.ref)));
    }
    // For the first request, pass in families.  Using the data or setState is async
    // and will be null when we make this request
    fetchEvents(defaultDate, true, families);
  };
  useEffect(() => {
    onLoad();
  }, []);
  const fetchEvents = async (
    newDate: Date,
    includeExternalEvents: boolean,
    families?: Family[],
  ) => {
    const familyData = families || familiesData?.families || [];
    const todayMonth = newDate.getMonth() + 1;
    setParams({
      ...params,
      month: todayMonth,
      year: newDate.getFullYear(),
      day: newDate.getDate(),
    });

    // For week view - there can be two months in a week
    const oneWeekLater = new Date(newDate);
    oneWeekLater.setDate(oneWeekLater.getDate() + 7);
    const oneWeekMonth = oneWeekLater.getMonth() + 1;
    let oneWeekYear = oneWeekLater.getFullYear();
    // Check if the month goes over December
    if (oneWeekMonth > 12) {
      oneWeekYear += 1;
    }

    const requests = [
      eventsRequest(
        new ListEventsRequest({
          ...params,
          familyRefs: familyData.map((f) => f.ref),
          month: todayMonth,
          year: newDate.getFullYear(),
          includeExternalEvents: includeExternalEvents,
          includeAdvisorEvents: true,
        }),
      ),
    ];

    // This can occur on week view where months overlap
    if (todayMonth !== oneWeekMonth) {
      requests.push(
        eventsRequest(
          new ListEventsRequest({
            ...params,
            familyRefs: familyData.map((f) => f.ref),
            month: oneWeekMonth,
            year: oneWeekYear,
            includeExternalEvents: includeExternalEvents,
            includeAdvisorEvents: true,
          }),
        ),
      );
    }

    const results = await Promise.all(requests);
    const allEvents = results.flatMap((result) => result?.eventNotices || []);

    setEvents((prevEvents) => [
      ...(!includeExternalEvents
        ? prevEvents.filter(
            (e) =>
              e.noticeType === EventNotice_NoticeType.NoticeType_EXTERNAL_EVENT,
          )
        : []),
      ...allEvents,
    ]);
  };

  return (
    <FlexPage
      leftAligned
      fullHeight
      sx={{
        padding: isMobile ? "16px 6px 0" : "16px 32px 0",
        maxWidth: "1400px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <AdvisorCalendarTodos />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "10px",
            flexGrow: 1,
          }}
        >
          <FayeCalendar
            accountType="advisor"
            events={events.filter((e) => {
              if (filteredFamilies === null) {
                return true;
              }
              return (
                filteredFamilies.has(e.familyRef) ||
                ((e.externalAdvisorCalendarRef ||
                  // Handle faye owned non-family events.
                  !e.familyRef) &&
                  filteredFamilies.has(ADVISOR_CALENDAR))
              );
            })}
            calendarFilters={[
              ...(familiesData?.families || [])
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((f) => ({
                  key: f.ref,
                  name: f.name,
                  color: familyColorMap?.[f.ref][0],
                })),
              // Handle faye owned non-family events.
              {
                name: "My Calendar",
                key: ADVISOR_CALENDAR,
              },
            ]}
            setEnabledFilters={(filters) => {
              setFilteredFamilies(new Set(filters.map((f) => f.key)));
            }}
            getEventStyles={(event) => {
              const colors = familyColorMap?.[event.familyRef];
              return {
                backgroundColor: colors?.[0] || defaultColor[0],
                color: colors?.[1] || defaultColor[1],
              };
            }}
            refreshEvents={async () => {
              fetchEvents(
                new Date(params.year, params.month - 1, params.day),
                true,
              );
            }}
            handleNavigate={async (d) => {
              fetchEvents(d, false);
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </FlexPage>
  );
};
