import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Chip, Typography, Button } from "@mui/material";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { useGetFamilyDetails } from "services/advisor";

import ActivityFeed from "components/activity/ActivityFeed";
import Loading from "components/common/Loading";
import { Family, FamilyStatus } from "protogen/advisors_service_pb";
import TwoPanelGrid, { Panel } from "components/details-page/TwoPanelGrid";
import TabbedDetailsPanel from "components/details-page/TabbedDetailsPanel";
import { Note } from "protogen/notes_pb";
import useIsMobile from "components/hooks/useIsMobile";
import { Task } from "protogen/tasks_pb";
import { SearchContext } from "components/context/SearchContextProvider";
import FamilyTaskSection from "components/family/FamilyTaskSection";
import FamilySecretsSection from "components/family/FamilySecretsSection";
import { SecretStub } from "protogen/secrets_pb";
import { FactSectionStub, FactStub } from "protogen/facts_pb";
import FamilyNotesSection from "components/family/FamilyNotesSection";
import Breadcrumbs from "components/common/Breadcrumbs";
import SuggestionActions, {
  SuggestionActionsHandle,
} from "components/activity/SuggestionActions";
import { ContactRound, MessageSquareText } from "lucide-react";
import { useNavigate } from "react-router";
import { Advisor, UserPermission } from "protogen/common_pb";
import { PlanUtilization } from "protogen/common_pb";
import TimeUtilization from "components/time-utilization/TimeUtilization";
import NotFound from "components/NotFound";
import useTitle from "components/hooks/useTitle";
import FamilyCalendarSection from "components/family/FamilyCalendarSection";
import {
  FEATURE_FLAGS,
  isFeatureEnabled,
} from "../components/helpers/GatedComponent";
import FamilySettingsSection from "../components/family/FamilySettingsSection";

interface LeftFamilyPanelProps {
  family: Family;
  familyNotes: Note;
  tasks: Task[];
  secrets: SecretStub[];
  facts: FactStub[];
  factSections: FactSectionStub[];
  setShowMessages: (s: boolean) => void;
  refresh: () => void;
  primaryAdvisor: Advisor;
  advisorPermissions: UserPermission[];
  planUtilization: PlanUtilization;
}
const LeftFamilyPanel = ({
  family,
  familyNotes,
  tasks,
  refresh,
  secrets,
  facts,
  factSections,
  primaryAdvisor,
  advisorPermissions,
  planUtilization,
}: LeftFamilyPanelProps) => {
  const [searchParams] = useSearchParams();
  let panels = [
    // <Section key={"Suggestions"} title={"Suggestions"}>
    //   <CardsContainer />
    // </Section>,
    <FamilyNotesSection
      key={"Notes"}
      title={"Family notes"}
      note={familyNotes}
      facts={facts}
      sections={factSections}
      familyRef={family.ref}
      family={family}
      refresh={refresh}
      advisorPermissions={advisorPermissions}
      primaryAdvisor={primaryAdvisor}
    />,
    <FamilyCalendarSection
      title={"Calendar"}
      key={"Calendar"}
      family={family}
      fullHeight
    />,
    <FamilyTaskSection
      title={"Tasks"}
      key={"Open Tasks"}
      family={family}
      tasks={tasks}
      planUtilization={planUtilization}
    />,
    <FamilySecretsSection
      title={"Secrets"}
      secrets={secrets}
      family={family}
      key="secrets"
    />,
  ];
  if (isFeatureEnabled(FEATURE_FLAGS.FAMILY_SETTINGS.value)) {
    panels.push(
      <FamilySettingsSection
        title={"Settings"}
        key={"settings"}
        family={family}
      />,
    );
  }
  let defaultTab = undefined;
  if (searchParams.has("factRef")) {
    defaultTab = panels.findIndex((p) => p.type === FamilyNotesSection);
  } else if (searchParams.has("secretRef")) {
    defaultTab = panels.findIndex((p) => p.type === FamilySecretsSection);
  }
  return (
    <TabbedDetailsPanel
      panels={panels}
      defaultTab={defaultTab}
      sx={{ overflowY: "hidden", paddingRight: "20px", gap: "0px" }}
    />
  );
};

const getFamilyBadge = (family: Family) => {
  const values = new Map<FamilyStatus, [string, string, string]>([
    [FamilyStatus.ACTIVE, ["Active", "#ECF4EC", "#198282"]],
    [
      FamilyStatus.PROSPECT,
      ["Prospect", "rgba(251, 242, 229, 0.95)", "#9A6518"],
    ],
    [FamilyStatus.DEACTIVATED, ["Inactive", "#F5F5F5", "#6B6E7B"]],
    [FamilyStatus.TEST, ["Demo", "#FFF", "#6B6E7B"]],
    [FamilyStatus.DEMO, ["Demo", "#FFF", "#6B6E7B"]],
  ]);
  if (!values.has(family.status)) {
    // Includes NOT_SET and ACTIVE
    return null;
  }
  const [copy, bgColor, textColor] = values.get(family.status)!;
  return (
    <Chip
      label={copy}
      sx={{
        height: "35px",
        fontSize: "16px",
        fontWeight: "600",
        borderRadius: "100px",
        padding: "8px 12px",
        background: bgColor,
        span: { color: textColor },
        ...(bgColor === "#FFF" && { border: "1px solid #D4D4D4" }),
      }}
    />
  );
};

const getInvitationButton = (family: Family, onClick: () => void) => {
  return (
    <Button onClick={onClick} variant="outlined" size="small">
      Invite to join
    </Button>
  );
};

const getTaskTimeUtilization = ({
  planUtilization,
}: {
  planUtilization?: PlanUtilization;
}) => {
  return <TimeUtilization planUtilization={planUtilization} />;
};

const getPanelHeaderActions = (
  family: Family,
  onClick: () => void,
  planUtilization?: PlanUtilization,
  mobile?: boolean,
) => {
  const includeInviteButton = !(
    family.status === FamilyStatus.DEACTIVATED ||
    family.status === FamilyStatus.ACTIVE
  );
  const showTimeUtilization = family.status === FamilyStatus.ACTIVE;
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        alignItems: "end",
        justifyContent: "end",
        gap: "16px",
      }}
    >
      {!showTimeUtilization && (
        <Box
          sx={{
            display: "flex",
            width: "100%",
            alignItems: "center",
            justifyContent: "end",
            gap: "16px",
            ...(mobile ? { justifyContent: "center", padding: "12px" } : {}),
          }}
        >
          {includeInviteButton && getInvitationButton(family, onClick)}
          {!mobile ? getFamilyBadge(family) : null}
        </Box>
      )}
      {showTimeUtilization && (
        <Box>
          {getTaskTimeUtilization({
            planUtilization: planUtilization,
          })}
        </Box>
      )}
    </Box>
  );
};

export default () => {
  const { setFamily } = useContext(SearchContext);
  const navigate = useNavigate();
  const { hash } = useLocation();
  const isMobile = useIsMobile();
  const [showMessages, setShowMessages] = useState(true);
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  let params = useParams();
  const actionsRef = useRef<SuggestionActionsHandle | null>(null);
  const { data, request, errorCode } = useGetFamilyDetails((r) => {
    if (r.family) {
      setFamily(r.family);
      setHasLoaded(true);
    }
  });
  useTitle(
    data?.family?.name ? `Families | ${data?.family?.name}` : "Families",
  );

  useEffect(() => {
    // Clear it so we don't show the wrong family while loading.
    setHasLoaded(false);
    request({ familyRef: params.familyRef! });
  }, [params.familyRef]);
  if (errorCode === 404) return <NotFound title={"Family not found"} />;
  if (!hasLoaded || !data) {
    return <Loading />;
  }
  const { family, familyNotes, activeTasks, secrets, facts, planUtilization } =
    data;
  // Not sure why these are nullable. TS+protobuf problem for another day.
  if (!family) {
    throw Error("Family type expected.");
  }
  const refresh = () => {
    request({ familyRef: params.familyRef! });
  };

  const onInviteClick = () => {
    navigate(`/families-invitation/${encodeURIComponent(family.ref)}`);
  };
  return (
    <>
      <TwoPanelGrid
        key={family.ref}
        defaultPanel={hash.match(/#tab-\d+/) ? Panel.Left : Panel.Right}
        headerAction={getPanelHeaderActions(
          family,
          onInviteClick,
          planUtilization,
        )}
        onChangePanel={(panel) => {
          panel === Panel.Right
            ? navigate("#", { replace: true })
            : navigate("#tab-0", { replace: true });
        }}
        leftPanel={{
          title: <Breadcrumbs breadcrumbs={[{ name: "Family details" }]} />,
          subtitle: (
            <Box sx={{}}>
              <Typography variant="display">{family.name}</Typography>
            </Box>
          ),
          switchContent: (
            <ContactRound size={20} strokeWidth="2px" stroke="#6B6E7B" />
          ),
          content: (
            <>
              {isMobile
                ? getPanelHeaderActions(family, onInviteClick, undefined, true)
                : null}
              <LeftFamilyPanel
                family={family}
                tasks={activeTasks}
                familyNotes={familyNotes}
                secrets={secrets}
                setShowMessages={setShowMessages}
                refresh={refresh}
                facts={facts}
                factSections={data.factSections}
                primaryAdvisor={data.primaryAdvisor}
                advisorPermissions={data.advisorPermissions}
                planUtilization={planUtilization}
              />
            </>
          ),
        }}
        rightPanel={{
          sx: { backgroundColor: "#FDFAF7" },
          title: null,
          subtitle: isMobile ? (
            <Box sx={{}}>
              <Typography variant="h2Serif" sx={{ fontWeight: 700 }}>
                {family.name}
              </Typography>
            </Box>
          ) : null,
          switchContent: (
            <MessageSquareText size={20} strokeWidth="2px" stroke="#6B6E7B" />
          ),
          content: family.familyMembers.length ? (
            <ActivityFeed
              family={data.family!}
              showMessages={showMessages}
              suggestionActions={{
                suggestTask: (t) => {
                  actionsRef.current?.suggestTask(t);
                },
                suggestFact: (f) => {
                  actionsRef.current?.suggestFact(f);
                },
                suggestEvent: (e) => {
                  actionsRef.current?.suggestEvent(e);
                },
              }}
              primaryAdvisor={data.primaryAdvisor}
              advisorPermissions={data.advisorPermissions}
            />
          ) : null,
        }}
      />
      <SuggestionActions
        ref={actionsRef}
        familyRef={family.ref}
        onFactSuggested={refresh}
        onEventSuggested={refresh}
        // These get redirected.
        // onTaskSuggested={refresh}
      />
    </>
  );
};
