import {
  Family,
  FetchInvoiceHistoryResponse_InvoiceMonth,
} from "protogen/advisors_service_pb";
import { Box, Button, Collapse, Typography } from "@mui/material";
import { SectionProps } from "../details-page/Section";
import { ChevronDown, ChevronUp } from "lucide-react";
import { useContext, useEffect, useState } from "react";
import { useFetchInvoiceHistory } from "../../services/advisor";
import Loading from "../common/Loading";
import LinkRouter from "../navigation/LinkRouter";
import { CurrentUserContext } from "../context/RequireAuth";
import { isUserAdmin } from "../../common/userUtils";
import { pluralize } from "../../common/utils";
// import useIsMobile from "components/hooks/useIsMobile";

const InvoiceMonth = ({
  month,
}: {
  month: FetchInvoiceHistoryResponse_InvoiceMonth;
}) => {
  const [expanded, setExpanded] = useState(false);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        borderRadius: "12px",
        border: "1px solid #D4D4D4",
        padding: "20px 16px",
      }}
    >
      <Box
        sx={{
          display: "flex",
        }}
      >
        <Typography variant="body" sx={{ flex: "2" }}>
          {month.startDate}
        </Typography>
        <Typography variant="body" sx={{ flex: "1" }}>
          ${month.amountCents / 100}
        </Typography>
        <Typography variant="body" sx={{ flex: "1" }}>
          {Math.round(month.totalMinutes / 60 / 0.25) * 0.25} hours
        </Typography>
        <Typography variant="body" sx={{ flex: "1" }}>
          {month.tasksCount} {pluralize("task", month.tasksCount)}
        </Typography>
        {expanded ? (
          <ChevronUp
            size={24}
            stroke="#756F6C"
            onClick={() => setExpanded(!expanded)}
          />
        ) : (
          <ChevronDown
            size={24}
            stroke="#756F6C"
            onClick={() => setExpanded(!expanded)}
          />
        )}
      </Box>
      <Collapse in={expanded}>
        {/* Collapse content container*/}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "12px",
            padding: "12px 0 0 0",
          }}
        >
          {!month.tasks.length && (
            <Typography variant="body">No work recorded</Typography>
          )}
          {/* Task entry item */}
          {month.tasks.map((task) => (
            <Box key={task.ref}>
              <LinkRouter to={`/tasks/${encodeURIComponent(task.ref)}`}>
                <Button variant="text" href="" sx={{ height: "auto" }}>
                  {task.title}
                </Button>
              </LinkRouter>
              {task.chunks.map((chunk, i) => (
                <Box
                  key={i}
                  sx={{ display: "flex", gap: "8px", alignItems: "center" }}
                >
                  <Typography variant="bodyHeavy">{chunk.minutes}m</Typography>
                  <Typography variant="body">
                    {chunk.description || "No description"}
                  </Typography>
                  <Typography variant="bodySmall" color="#686868">
                    {chunk.date}
                  </Typography>
                </Box>
              ))}
            </Box>
          ))}
        </Box>
      </Collapse>
    </Box>
  );
};

const BillingHistory = ({ family }: { family: Family }) => {
  const { request, data, loading } = useFetchInvoiceHistory();
  useEffect(() => {
    request({
      familyRef: family.ref,
    });
  }, []);
  if (loading) {
    return <Loading />;
  }
  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
        <Typography variant="h3">Monthly Summaries</Typography>
        {(data?.invoices || []).map((month, i) => (
          <InvoiceMonth key={i} month={month} />
        ))}
      </Box>
    </Box>
  );
};

interface Props extends SectionProps {
  family: Family;
}
export default ({ family }: Props) => {
  const currentUser = useContext(CurrentUserContext);
  const isAdvisor = currentUser?.ref === family.advisorRef;
  const isAdmin = isUserAdmin(currentUser);

  // const isMobile = useIsMobile();
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "48px" }}>
      {/*<Box sx={{ display: "flex", flexDirection: "column" }}>*/}
      {/*  <Box>*/}
      {/*    <Typography variant="h3">Family details</Typography>*/}

      {/*    <Typography variant="h4">Accounts</Typography>*/}
      {/*  </Box>*/}
      {/*</Box>*/}

      {/*<Box sx={{ display: "flex", flexDirection: "column" }}>*/}
      {/*  <Box>*/}
      {/*    <Typography variant="h3">Advisor details</Typography>*/}
      {/*  </Box>*/}
      {/*</Box>*/}

      {/*<Box sx={{ display: "flex", flexDirection: "column" }}>*/}
      {/*  <Box>*/}
      {/*    <Typography variant="h3">Plan details</Typography>*/}
      {/*  </Box>*/}
      {/*</Box>*/}
      {isAdvisor || isAdmin ? <BillingHistory family={family} /> : null}
    </Box>
  );
};
