import { ViewProps } from "react-big-calendar";
import EventsList, { EventListProps } from "./EventsList";
import { EventNotice } from "protogen/calendar_pb";

export interface CustomAgendaViewProps extends ViewProps<EventNotice, object> {
  eventListProps: EventListProps;
}

const CustomAgenda = ({
  events,
  date,
  onSelectEvent,
  eventListProps,
}: CustomAgendaViewProps) => {
  // Convert the date prop to a Date object
  const currentDate = new Date(date);

  // Check if the current view is showing the current month/year
  const now = new Date();
  const queryParams = new URLSearchParams(location.search);
  const dateCursorParam = queryParams.get("dateCursor");
  let dateCursor = null;
  if (dateCursorParam) {
    dateCursor = new Date(Number(dateCursorParam));
  }

  const isCurrentMonth =
    now.getMonth() === currentDate.getMonth() &&
    now.getFullYear() === currentDate.getFullYear();

  // filter eventListProp events to only the matching events.
  const eventSet = new Set((events || []).map((e) => e.eventRef));
  const filteredEventNotices = eventListProps.events.filter((e) => {
    // Assumes that our navigation is only by month.
    const start = new Date(Number(e.startSec) * 1000);
    const end = new Date(Number(e.endSec) * 1000);
    return (
      eventSet.has(e.eventRef) &&
      ((start.getMonth() === currentDate.getMonth() &&
        start.getFullYear() === currentDate.getFullYear()) ||
        (end.getMonth() === currentDate.getMonth() &&
          end.getFullYear() === currentDate.getFullYear()))
    );
  });
  return (
    <div
      style={{
        overflowY: "auto",
        position: "relative",
        msOverflowStyle: "none",
        scrollbarWidth: "none",
      }}
    >
      <EventsList
        {...eventListProps}
        cursorType={dateCursor ? "date" : isCurrentMonth ? "today" : null}
        cursorDate={dateCursor ? dateCursor : null}
        selectEvent={(ref: string) => {
          const event = filteredEventNotices.find((e) => e.eventRef === ref);
          if (event) {
            onSelectEvent(event);
          }
        }}
        events={filteredEventNotices}
      />
    </div>
  );
};

export default CustomAgenda;
