import { ReactNode, useEffect, useState } from "react";
import NotImplemented from "../NotImplemented";

export type FeatureFlag = {
  value: string;
  title: string;
  description: string | null;
};
type FeatureFlags = {
  [key: string]: FeatureFlag;
};
export const FEATURE_FLAGS: FeatureFlags = {
  // ADVISOR_PAYMENT_MANAGEMENT: {
  //   value: "faye.advisor-payment-management",
  //   title: "Management payments",
  //   description: "Managed linked accounts for payments.",
  // },
  // RECOMMENDATIONS_ON_TASKS: {
  //   value: "faye.recommendations-on-tasks",
  //   title: "Recommendations on Tasks",
  //   description: "Recommendations from the forum displayed on tasks.",
  // },
  // CALENDAR: {
  //   value: "faye.calendar",
  //   title: "Calendar UX",
  //   description: "View the new calendar UX.",
  // },
  FAMILY_SETTINGS: {
    value: "faye.family-settings",
    title: "Enables family settings tab.",
    description: "Enables .",
  },
  // SCHEDULED_MESSAGES: {
  //   value: "faye.schedule-messages",
  //   title: "Scheduled Messages",
  //   description: "Scheduled text messages.",
  // },
  // GROUP_CALLS: {
  //   value: "faye.group-calls",
  //   title: "Group Calls",
  //   description: "Allows calling all family members in a conference call.",
  // },
  // GROUP_SMS: {
  //   value: "faye.group-sms",
  //   title: "Group SMS",
  //   description: "Exchange group messages with family members.",
  // },
  // Not deleting these because they are still in the localstorage namespace.
  // EVENTS: {
  //   value: "faye.calendar-events",
  //   title: "Calendar Events",
  //   description: "Events on family pages and a standalone calendar page.",
  // },
  // CATEGORIES: {
  //   value: "faye.categories",
  //   title: "Recommendations and Categories",
  //   description: "Enables the category and subcategory features of the forum.",
  // },
  // DMS: {
  //   value: "faye.direct-messaging",
  //   title: "Direct Messaging",
  //   description: "Enables direct messaging for advisors..",
  // },
  // CALENDER_REMINDERS: {
  //   value: "faye.calendar-reminders",
  //   title: "Calendar Reminders",
  //   description: "Enables reminder notifications for calendar events",
  // },
};

export const isFeatureEnabled = (featureName: string) => {
  const val = localStorage.getItem(featureName);
  return !!val;
};

type Props = {
  featureName: string;
  children: ReactNode;
  returnNull?: boolean;
};
export default ({ featureName, children, returnNull }: Props) => {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  useEffect(() => {
    const val = isFeatureEnabled(featureName);
    if (val) {
      setIsEnabled(true);
    }
  }, []);

  if (!isEnabled) {
    return returnNull ? null : <NotImplemented name="Feature" />;
  }
  return <>{children}</>;
};
