import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Family, FormErrors, prettyFamilyStatus } from "./types";
import { FamilyStatus } from "protogen/advisors_service_pb";
import { useState, useEffect, useContext } from "react";
import AdvisorSelect from "../creation/AdvisorSelect";
import AddressAutocomplete, { Location } from "../common/AddressAutocomplete";
import FamilyPlanDetails from "./FamilyPlanDetails";
import { hasRole, ROLE_ADMIN } from "../../common/userUtils";
import BillingInfo from "./BillingInfo";
import { CurrentUserContext } from "../context/RequireAuth";
import PlatformFeeSelect from "./PlatformFeeSelect";
import { DatePicker } from "@mui/x-date-pickers";

const EndDate = ({
  date,
  onDateChange,
  error,
  disabled = false,
}: {
  date: Date | null;
  onDateChange: (date: Date | null) => void;
  error?: string;
  disabled?: boolean;
}) => {
  const [dateOpen, setDateOpen] = useState(false);
  return (
    <DatePicker
      sx={{
        width: "100%",
        flex: 1,
        ".MuiButtonBase-root": { marginRight: "unset" },
      }}
      label="End date"
      defaultValue={date}
      disabled={disabled}
      onChange={(dte) => {
        if (dte === null) return;
        onDateChange(dte);
      }}
      format="MM/dd/yyyy"
      open={dateOpen}
      onClose={() => setDateOpen(false)}
      slotProps={{
        textField: {
          error: !!error,
          helperText: error,
          onClick: () => setDateOpen(true),
        },
      }}
    />
  );
};

interface Props {
  familyData: Family;
  setFamilyData: (f: Family) => void;
  errors: FormErrors | null;
  selectAdvisor?: boolean;
}

export default ({
  familyData,
  setFamilyData,
  errors,
  selectAdvisor = false,
}: Props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [parsedAddress, setParsedAddress] = useState<Location>();
  const currentUser = useContext(CurrentUserContext);

  useEffect(() => {
    if (!familyData.address) return;
    setParsedAddress(JSON.parse(familyData.address));
    setIsLoaded(true);
  }, [familyData.address]);
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "16px",
        marginTop: "16px",
        marginBottom: "16px",
      }}
    >
      <Typography variant={"bodyHeavy"}>Family Details</Typography>
      {selectAdvisor && (
        <AdvisorSelect
          title="Advisor"
          disabled={false}
          isAdmin={true} // Can't get into this state without being an admin
          error={errors?.assigneeRef}
          selectedAdvisor={familyData.advisorRef || null}
          onChange={(v) => setFamilyData({ ...familyData, advisorRef: v! })}
        />
      )}
      <TextField
        error={!!errors?.name}
        helperText={errors?.name}
        value={familyData.name}
        onChange={(e) => setFamilyData({ ...familyData, name: e.target.value })}
        label="Family Name"
        margin="normal"
        size="small"
        sx={{ marginTop: 0 }}
      />
      <FamilyPlanDetails
        disabled={
          !hasRole(currentUser, ROLE_ADMIN) &&
          familyData.status === FamilyStatus.ACTIVE
        }
        planDetails={{
          startDate: familyData.startDate
            ? new Date(familyData.startDate)
            : undefined,
          product: familyData.product,
          coupon: familyData.introCoupon,
        }}
        errors={{}}
        onPlanDetailsChange={(details) => {
          setFamilyData({
            ...familyData,
            product: details.product,
            introCoupon: details.coupon,
            startDate: details.startDate
              ? details.startDate.toLocaleDateString()
              : null,
          });
        }}
        stacked={true}
      />
      {isLoaded && (
        <AddressAutocomplete
          label={"Address"}
          error={errors?.location}
          initialValue={parsedAddress?.formattedAddress || ""}
          setValue={(location) => {
            setParsedAddress(location);
            setFamilyData({
              ...familyData,
              address: JSON.stringify(location),
            });
          }}
          handleCopy={true}
        />
      )}
      <FormControl fullWidth>
        <InputLabel id="select-label">Status</InputLabel>
        <Select
          fullWidth
          labelId="select-label"
          id="select-label"
          value={familyData.status}
          label="Status"
          onChange={(e) =>
            setFamilyData({
              ...familyData,
              status: e.target.value as FamilyStatus,
            })
          }
        >
          <MenuItem value={FamilyStatus.ACTIVE}>
            {prettyFamilyStatus(FamilyStatus.ACTIVE)}
          </MenuItem>
          <MenuItem value={FamilyStatus.PROSPECT}>
            {prettyFamilyStatus(FamilyStatus.PROSPECT)}
          </MenuItem>
          <MenuItem value={FamilyStatus.DEMO}>
            {prettyFamilyStatus(FamilyStatus.DEMO)}
          </MenuItem>
          <MenuItem value={FamilyStatus.TEST}>
            {prettyFamilyStatus(FamilyStatus.TEST)}
          </MenuItem>
          <MenuItem value={FamilyStatus.DEACTIVATED}>
            {prettyFamilyStatus(FamilyStatus.DEACTIVATED)}
          </MenuItem>
          <MenuItem value={FamilyStatus.DEACTIVATED_PROSPECT}>
            {prettyFamilyStatus(FamilyStatus.DEACTIVATED_PROSPECT)}
          </MenuItem>
        </Select>
      </FormControl>
      {hasRole(currentUser, ROLE_ADMIN) && (
        <>
          <EndDate
            date={familyData.endDate ? new Date(familyData.endDate) : null}
            onDateChange={(d) =>
              setFamilyData({
                ...familyData,
                endDate:
                  d && !isNaN(d.getTime()) ? d.toLocaleDateString() : null,
              })
            }
          />
          <PlatformFeeSelect
            value={familyData.platformFeeSchedule}
            onUpdate={(v) =>
              setFamilyData({
                ...familyData,
                platformFeeSchedule: v,
              })
            }
          />
          {familyData.billingInfo?.stripeCustomerId ? (
            <BillingInfo
              stripeCustomerId={familyData.billingInfo?.stripeCustomerId}
            />
          ) : (
            <TextField
              error={!!errors?.stripeCustomerId}
              helperText={errors?.stripeCustomerId}
              defaultValue={familyData.stripeCustomerId}
              onChange={(e) =>
                setFamilyData({
                  ...familyData,
                  stripeCustomerId: e.target.value,
                })
              }
              label="Stripe Customer ID"
              margin="normal"
              size="small"
              sx={{ marginTop: 0 }}
            />
          )}
        </>
      )}
    </Box>
  );
};
