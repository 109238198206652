import { useParams, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useGetMemberTask } from "services/tasks";
import Loading from "components/common/Loading";
import { Box } from "@mui/system";
import useIsMobile from "components/hooks/useIsMobile";
import TaskDetailsPanel from "components/tasks/TaskDetailsPanel";
import TaskDropDown from "components/tasks/TaskDropDown";
import Breadcrumbs from "components/common/Breadcrumbs";
import { Task } from "protogen/tasks_pb";
import NotFound from "components/NotFound";

export default ({ addTask = false }: { addTask?: boolean }) => {
  let params = useParams();
  const location = useLocation();
  const inspiredTask = location.state?.inspiredTask;
  const initialTaskTitle = location.state?.taskTitle;
  const [task, setTask] = useState<Task | undefined>(undefined);
  const [isUnsavedTask, setIsUnsavedTask] = useState(false);
  const family = location.state?.family;
  const { loading, request, error } = useGetMemberTask((r) => {
    setTask(r.task);
  });
  const isMobile = useIsMobile();
  useEffect(() => {
    if (addTask) {
      const newTask = new Task({ state: 1, priority: "medium" });
      if (inspiredTask) {
        newTask.title = inspiredTask.title;
        newTask.sourceEntityType = "task_inspiration";
        newTask.sourceEntityRef = inspiredTask.ref;
      } else if (initialTaskTitle) {
        newTask.title = initialTaskTitle;
      }
      setTask(newTask);
      setIsUnsavedTask(true);
      return;
    }
    if (isUnsavedTask) return;
    request({ taskRef: params.taskRef! });
  }, [params.taskRef]);

  if (error) return <NotFound title={"Task not found"} />;
  if (!task || loading) return <Loading />;

  return (
    <Box
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "20px" : "0",
      }}
    >
      <Box display="flex" flexDirection="row" maxWidth="850px">
        <Box display="flex" flexDirection="column" width="100%">
          <Box
            sx={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Breadcrumbs
              breadcrumbs={[
                {
                  name: "Home",
                  link: "/home",
                },
                { name: "Tasks", back: true },
              ]}
            />
            {!isUnsavedTask && (
              <TaskDropDown
                task={task}
                refresh={() => request({ taskRef: task?.ref! })}
              />
            )}
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          height: "100%",
        }}
      >
        <TaskDetailsPanel
          task={task}
          updateTask={(t) => {
            setTask(t);
          }}
          accountType="member"
          isUnsavedTask={isUnsavedTask}
          family={family}
        />
      </Box>
    </Box>
  );
};
