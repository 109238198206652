import { Box, Typography, Button, SpeedDial } from "@mui/material";
import SpeedDialIcon from "@mui/material/SpeedDialIcon";
import { useContext, useEffect, useState } from "react";
import Loading from "components/common/Loading";
import GridPage from "components/layout/GridPage";

import { SearchContext } from "components/context/SearchContextProvider";
import AddEventDialog from "components/calendar/AddEventDialog";
import { useListCalendarEvents } from "services/calendar";
import { ListCalendarEventsRequest } from "protogen/calendar_service_pb";
import { EventNotice } from "protogen/calendar_pb";
import EventsList from "components/calendar/EventsList";
import { useListAdvisorFamilies } from "services/advisor";
import CalendarFilters from "components/calendar/CalendarFilters";
import { CalendarParameters, defaultParameters } from "../types/calendars";
import { getMonthName } from "components/calendar/utils";
import useIsMobile from "components/hooks/useIsMobile";
import { useLocation, useParams } from "react-router-dom";
import ViewEventDialog from "components/calendar/ViewEventDialog";
import EditEventDialog from "components/calendar/EditEventDialog";
import { useNavigate } from "react-router";
import useTitle from "components/hooks/useTitle";

type StatefulParametersReturnType = [
  CalendarParameters,
  React.Dispatch<React.SetStateAction<CalendarParameters>>,
];

const useStatefulParameters = (): StatefulParametersReturnType => {
  const location = useLocation();
  const navigate = useNavigate();

  const defaultParamsFromQuery = (query: string): CalendarParameters => {
    const base = defaultParameters();
    const queryParams = new URLSearchParams(query);
    const familyParam = queryParams.get("familyRefs");
    if (familyParam) {
      base.familyRefs = familyParam
        .split(",")
        .map((r) => decodeURIComponent(r));
    }
    const startSec = queryParams.get("startSec");
    if (startSec) {
      base.startSec = BigInt(parseInt(startSec));
    }
    const endSec = queryParams.get("endSec");
    if (endSec) {
      base.endSec = BigInt(parseInt(endSec));
    }
    const _check = (s: string) =>
      queryParams.has(s) && queryParams.get(s) === "1";
    base.ignoreTasks = _check("ignoreTasks");
    base.ignoreEvents = _check("ignoreEvents");
    // When events are unbound in the future (e.g. "Today"), we include overdue tasks.
    base.includeOverdueTasks = !base.endSec;
    return base;
  };
  const [params, setParams] = useState<CalendarParameters>(
    defaultParamsFromQuery(location.search),
  );

  // Function to update URL parameters
  const updateUrlParams = (newParams: CalendarParameters) => {
    const searchParams = new URLSearchParams();
    if (newParams.startSec && newParams.endSec) {
      searchParams.set("startSec", newParams.startSec.toString());
      searchParams.set("endSec", newParams.endSec.toString());
    }
    if (
      !newParams.allFamiliesSelected &&
      (newParams.familyRefs || []).length > 0
    ) {
      searchParams.set("familyRefs", (newParams.familyRefs || []).join(","));
    }
    if (newParams.ignoreTasks) {
      searchParams.set("ignoreTasks", "1");
    }
    if (newParams.ignoreEvents) {
      searchParams.set("ignoreEvents", "1");
    }
    navigate({ search: searchParams.toString() });
  };

  // Effect to update URL when state changes
  useEffect(() => {
    updateUrlParams(params);
  }, [params]);

  return [params, setParams];
};

const buildRequest = (
  params: CalendarParameters,
): ListCalendarEventsRequest => {
  return new ListCalendarEventsRequest({
    familyRefs: params.familyRefs || undefined,
    startSec: params.startSec,
    endSec: params.endSec || undefined,
    ignoreTasks: params.ignoreTasks,
    ignoreEvents: params.ignoreEvents,
    includeOverdueTasks: params.includeOverdueTasks,
  });
};

export default () => {
  let params = useParams();
  const isMobile = useIsMobile();
  useTitle("Datebook");
  const { setCalendarOnly } = useContext(SearchContext);
  const [month, setMonth] = useState<string | null>(null);
  const [dateCursor, setDateCursor] = useState(false);
  const [events, setEvents] = useState<EventNotice[]>([]);
  const [addModalOpen, setAddModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [filterParams, setFilterParams] = useStatefulParameters();
  const [cursor, setCursor] = useState<string | null>(null);
  const { data: familiesData, request: familiesRequest } =
    useListAdvisorFamilies((r) => {
      setFilterParams((prevState) => {
        const familyRefs = prevState.familyRefs || r.families.map((f) => f.ref);
        return {
          ...prevState,
          // Respect anything pre-selected.
          familyRefs: familyRefs,
          allFamiliesSelected: familyRefs.length === r.families.length,
        };
      });
    });
  useEffect(() => {
    familiesRequest();
  }, []);

  useEffect(() => {
    if (params.eventRef) {
      selectEvent(params.eventRef);
    }
  }, [params.eventRef]);

  const { data, request, loading } = useListCalendarEvents();
  const fetchEvents = async (newParams?: CalendarParameters) => {
    const resp = await request(buildRequest(newParams || filterParams));
    setCalendarOnly(true);
    setCursor(resp?.nextCursor || null);
    setEvents(resp?.eventNotices || []);
  };
  const nextPage = async () => {
    if (!cursor) return;
    const resp = await request(
      new ListCalendarEventsRequest({
        familyRefs: filterParams.familyRefs || [],
        ignoreTasks: filterParams.ignoreTasks,
        ignoreEvents: filterParams.ignoreEvents,
        startCursor: cursor,
        // Can't be tweaked in pagination!
        includeOverdueTasks: true,
        startSec: undefined,
        endSec: undefined,
      }),
    );
    setCursor(resp?.nextCursor || null);
    setEvents([...events, ...(resp?.eventNotices || [])]);
  };

  useEffect(() => {
    // Wait for families data to load
    if (familiesData !== null && data === null) {
      fetchEvents();
    }
  }, [familiesData]);

  const onUpdates = async (params: CalendarParameters) => {
    setFilterParams(params);
    await fetchEvents(params);
  };
  const onUpdateDate = (d: Date | null) => {
    if (d == null) {
      setMonth(null);
      setDateCursor(false);
    } else {
      setMonth(getMonthName(d));
      const now = new Date();
      setDateCursor(
        now.getMonth() === d.getMonth() &&
          now.getFullYear() === d.getFullYear(),
      );
    }
  };

  const onEnableEdit = (eventRef: string) => {
    setSelectedEvent(eventRef);
    setViewModalOpen(false);
    setEditModalOpen(true);
  };
  const selectEvent = (eventRef: string) => {
    setViewModalOpen(true);
    setSelectedEvent(eventRef);
  };
  const onEdited = async () => {
    setEditModalOpen(false);
    await fetchEvents();
  };

  return (
    <GridPage
      sx={{
        margin: isMobile ? "" : "64px min(7%, 100px)",
        maxWidth: "1000px",
        padding: isMobile ? "32px 24px" : "",
      }}
    >
      <Box
        sx={{
          flex: "1 1 100%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {!isMobile && (
          <>
            <Typography variant="display">Datebook</Typography>
            <Button
              variant="outlined"
              size="small"
              sx={{
                marginTop: "6px",
                padding: "8px 20px",
              }}
              onClick={() => setAddModalOpen(true)}
            >
              New event
            </Button>
          </>
        )}
      </Box>

      <CalendarFilters
        families={familiesData?.families || []}
        disabled={!data}
        parameters={filterParams}
        updateParameters={onUpdates}
        updateDateFilter={onUpdateDate}
      />
      <Box>
        {month && (
          <Typography
            variant="h1Serif"
            sx={{
              marginBottom: "28px",
            }}
          >
            {month}
          </Typography>
        )}
        {!data && <Loading />}
        {data && (
          <EventsList
            events={events}
            families={familiesData?.families}
            cursorType={dateCursor && events.length > 0 ? "today" : null}
            selectEvent={selectEvent}
          />
        )}
        {data && events.length === 0 && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
            }}
          >
            <Typography
              variant="body"
              sx={{
                color: "text.secondary",
              }}
            >
              No events to show
            </Typography>
          </Box>
        )}
        {data && filterParams.startSec && !filterParams.endSec && cursor && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              marginTop: "20px",
            }}
          >
            <Button
              variant="outlined"
              size="small"
              sx={{
                marginTop: "6px",
                padding: "8px 20px",
              }}
              onClick={nextPage}
              disabled={loading}
            >
              Show more
            </Button>
          </Box>
        )}
      </Box>
      {isMobile && (
        <SpeedDial
          open={addModalOpen}
          ariaLabel=""
          sx={{ position: "fixed", bottom: 32, right: 32 }}
          icon={<SpeedDialIcon />}
          onClick={() => setAddModalOpen(true)}
        />
      )}
      <AddEventDialog
        familyRef={
          filterParams.familyRefs && filterParams.familyRefs.length == 1
            ? filterParams.familyRefs[0]
            : undefined
        }
        selectFamily={true}
        open={addModalOpen}
        onClose={() => setAddModalOpen(false)}
        onCreated={() => {
          fetchEvents();
          setAddModalOpen(false);
        }}
      />
      <ViewEventDialog
        open={viewModalOpen}
        onClose={async (updated) => {
          setViewModalOpen(false);
          if (updated) {
            await fetchEvents();
          }
        }}
        primaryAction={onEnableEdit}
        eventRef={selectedEvent}
      />
      <EditEventDialog
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onEdited={onEdited}
        eventRef={selectedEvent}
      />
    </GridPage>
  );
};
