import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, TextField } from "@mui/material";
import auth from "components/nux/auth";
import { useAddMemberContacts } from "../../..//services/nux";
import { getStepPath } from "components/nux/utils";
import NuxContentLayout from "components/nux/NuxContentLayout";
import { useGetMemberDetails, useUpdateMembersFamily } from "services/member";
import { USER_NUX_TYPE } from "components/nux/constants";
import { EphemeralMember } from "components/member/types";
import PhoneInput from "components/creation/PhoneInput";
import { cleanPhone } from "../../../common/utils";
import { validateContact } from "components/member/utils";
import { FormErrors } from "components/member/types";
import useIsMobile from "components/hooks/useIsMobile";

const AccountForm = ({
  member,
  index,
  onChange,
  title,
  subtitle,
}: {
  member: EphemeralMember;
  index: number;
  onChange: (member: EphemeralMember) => void;
  title: string;
  subtitle: string;
}) => {
  const isMobile = useIsMobile();
  const disabled = index === 0;
  const [errors, setErrors] = useState<FormErrors>();
  const [hasLoaded, setHasLoaded] = useState<boolean>(false);
  const validate = () => {
    const errors = validateContact(member);
    setErrors(errors);
  };
  useEffect(() => {
    if (hasLoaded) {
      validate();
    }
    setHasLoaded(true);
  }, [member]);
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: " 16px" }}>
      <Box sx={{ display: "flex", alignItems: "center", gap: "12px" }}>
        <Typography variant="h3Serif">{title}</Typography>
        <Typography variant="body">{subtitle}</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          flexWrap: "wrap",
        }}
      >
        <TextField
          sx={{
            width: isMobile ? "100%" : undefined,
            flex: !isMobile ? 1 : undefined,
          }}
          disabled={disabled}
          margin="dense"
          label="First name"
          type="text"
          variant="outlined"
          value={member.firstName}
          onChange={(e) => {
            onChange({
              ...member,
              firstName: e.target.value,
            });
          }}
        />
        <TextField
          sx={{
            width: isMobile ? "100%" : undefined,
            flex: !isMobile ? 1 : undefined,
          }}
          disabled={disabled}
          margin="dense"
          label="Last name"
          type="text"
          variant="outlined"
          value={member.lastName}
          onChange={(e) => {
            onChange({
              ...member,
              lastName: e.target.value,
            });
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          gap: "24px",
          flexWrap: "wrap",
        }}
      >
        <PhoneInput
          disabled={disabled}
          value={member.primaryPhone}
          error={!!errors?.primaryPhone}
          helperText={errors?.primaryPhone}
          onChange={(phone) => {
            onChange({
              ...member,
              primaryPhone: cleanPhone(
                typeof phone == "string" ? phone : phone.target.value,
              ),
            });
          }}
          label="Phone"
          size="small"
          sx={{
            width: isMobile ? "100%" : undefined,
            flex: !isMobile ? 1 : undefined,
            marginTop: "8px",
            marginBottom: "4px",
          }}
          autoComplete="off"
        />
        <TextField
          sx={{
            width: isMobile ? "100%" : undefined,
            flex: !isMobile ? 1 : undefined,
          }}
          disabled={disabled}
          margin="dense"
          label="Email"
          name="username"
          type="email"
          variant="outlined"
          value={member.primaryEmail}
          error={!!errors?.primaryEmail}
          helperText={errors?.primaryEmail}
          onChange={(e) => {
            onChange({
              ...member,
              primaryEmail: e.target.value,
            });
          }}
        />
      </Box>
    </Box>
  );
};

export default () => {
  const [members, setMembers] = useState<EphemeralMember[]>([]);
  const [hasChanges, setHasChanges] = useState<boolean>(false);
  const navigate = useNavigate();
  const { request: updateFamilyRequest, loading: updateMembersFamilyLoading } =
    useUpdateMembersFamily();

  const { request: getMemberRequest, data } = useGetMemberDetails((r) => {
    let members = r?.family?.familyMembers.slice(0, 2) || [];
    setMembers(members);
    if (members.length < 2) {
      setMembers([
        ...members,
        { firstName: "", lastName: "", primaryPhone: "", primaryEmail: "" },
      ]);
    }
  });
  useEffect(() => {
    getMemberRequest();
  }, []);

  const { request, loading: addMemberContactLoading } = useAddMemberContacts(
    (r) => {
      if (r?.nuxDetails?.stepName) {
        const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.MEMBER);
        navigate(path);
      }
    },
  );

  const onContinue = async () => {
    if (data?.family?.ref) {
      if (hasChanges) {
        await updateFamilyRequest({
          familyRef: data?.family?.ref,
          updatedAddress: data?.family?.address,
          newMembers: members
            .filter((m) => !m.ref)
            .map((member) => ({
              firstName: member.firstName || "",
              lastName: member.lastName || "",
              primaryPhone: member.primaryPhone || "",
              primaryEmail: member.primaryEmail || "",
            })),
        });
      }
      request({
        token: auth.getAuthData(),
      });
    }
  };

  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "32px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
          }}
        >
          <Typography variant="title">Account holders</Typography>
          <Typography variant="body">
            If you have a spouse or partner you’d like to have an account with
            us for interfacing with{" "}
            {data?.primaryAdvisor?.firstName || "your advisor"}, add them here.
          </Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "32px" }}>
          {members.map((member, index) => {
            return (
              <AccountForm
                title={index === 0 ? "Primary contact" : "Secondary contact"}
                subtitle={index === 0 ? "" : "optional"}
                key={index}
                member={member}
                index={index}
                onChange={(member) => {
                  setHasChanges(true);
                  setMembers(
                    members.map((currentMember, currentIndex) =>
                      currentIndex === index ? member : currentMember,
                    ),
                  );
                }}
              />
            );
          })}
        </Box>
        <Box sx={{ display: "flex", justifyContent: "end" }}>
          <Box>
            <Button
              sx={{ width: "100%" }}
              onClick={onContinue}
              disabled={updateMembersFamilyLoading || addMemberContactLoading}
            >
              Continue
            </Button>
          </Box>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
