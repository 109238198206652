// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/conversation.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64 } from "@bufbuild/protobuf";
import { Attachment } from "./common_pb.ts";
import { DocumentSuggestion, Suggestions } from "./suggestions_pb.ts";
import { Task } from "./tasks_pb.ts";

/**
 * @generated from enum protogen.TextMessageStatus
 */
export enum TextMessageStatus {
  /**
   * @generated from enum value: STATUS_UNSPECIFIED = 0;
   */
  STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATUS_SENT = 1;
   */
  STATUS_SENT = 1,

  /**
   * @generated from enum value: STATUS_PENDING = 2;
   */
  STATUS_PENDING = 2,

  /**
   * @generated from enum value: STATUS_UNDELIVERED = 3;
   */
  STATUS_UNDELIVERED = 3,

  /**
   * @generated from enum value: STATUS_RECEIVED = 4;
   */
  STATUS_RECEIVED = 4,

  /**
   * @generated from enum value: STATUS_SCHEDULED = 5;
   */
  STATUS_SCHEDULED = 5,
}
// Retrieve enum metadata with: proto3.getEnumType(TextMessageStatus)
proto3.util.setEnumType(TextMessageStatus, "protogen.TextMessageStatus", [
  { no: 0, name: "STATUS_UNSPECIFIED" },
  { no: 1, name: "STATUS_SENT" },
  { no: 2, name: "STATUS_PENDING" },
  { no: 3, name: "STATUS_UNDELIVERED" },
  { no: 4, name: "STATUS_RECEIVED" },
  { no: 5, name: "STATUS_SCHEDULED" },
]);

/**
 * @generated from message protogen.EmailMessage
 */
export class EmailMessage extends Message<EmailMessage> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string conversationRef = 2;
   */
  conversationRef = "";

  /**
   * @generated from field: string senderRef = 3;
   */
  senderRef = "";

  /**
   * @generated from field: int64 timestampSec = 4;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: string subject = 5;
   */
  subject = "";

  /**
   * @generated from field: string text = 6;
   */
  text = "";

  /**
   * @generated from field: string html = 7;
   */
  html = "";

  /**
   * @generated from field: string teaserText = 8;
   */
  teaserText = "";

  /**
   * @generated from field: string senderDisplayName = 9;
   */
  senderDisplayName = "";

  /**
   * @generated from field: repeated protogen.Attachment attachments = 10;
   */
  attachments: Attachment[] = [];

  /**
   * @generated from field: string fromAddress = 11;
   */
  fromAddress = "";

  /**
   * @generated from field: repeated string toAddresses = 12;
   */
  toAddresses: string[] = [];

  /**
   * @generated from field: repeated string ccAddresses = 13;
   */
  ccAddresses: string[] = [];

  /**
   * @generated from field: repeated string bccAddresses = 14;
   */
  bccAddresses: string[] = [];

  /**
   * @generated from field: bool isDraft = 15;
   */
  isDraft = false;

  /**
   * @generated from field: bool isReply = 16;
   */
  isReply = false;

  /**
   * For email threads.
   *
   * @generated from field: repeated string eventRefs = 18;
   */
  eventRefs: string[] = [];

  /**
   * @generated from field: repeated string eventAttendeeRefs = 19;
   */
  eventAttendeeRefs: string[] = [];

  /**
   * @generated from field: bool isForwarded = 20;
   */
  isForwarded = false;

  /**
   * @generated from field: protogen.Suggestions threadSuggestions = 22;
   */
  threadSuggestions?: Suggestions;

  /**
   * only used in the activity feed.
   *
   * @generated from field: bool unread = 23;
   */
  unread = false;

  /**
   * @generated from field: repeated protogen.Task taskBookmarks = 24;
   */
  taskBookmarks: Task[] = [];

  constructor(data?: PartialMessage<EmailMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.EmailMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "senderRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "subject", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "text", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "html", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "teaserText", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 9, name: "senderDisplayName", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "attachments", kind: "message", T: Attachment, repeated: true },
    { no: 11, name: "fromAddress", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 12, name: "toAddresses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 13, name: "ccAddresses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 14, name: "bccAddresses", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 15, name: "isDraft", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 16, name: "isReply", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 18, name: "eventRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 19, name: "eventAttendeeRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 20, name: "isForwarded", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 22, name: "threadSuggestions", kind: "message", T: Suggestions },
    { no: 23, name: "unread", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 24, name: "taskBookmarks", kind: "message", T: Task, repeated: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): EmailMessage {
    return new EmailMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): EmailMessage {
    return new EmailMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): EmailMessage {
    return new EmailMessage().fromJsonString(jsonString, options);
  }

  static equals(a: EmailMessage | PlainMessage<EmailMessage> | undefined, b: EmailMessage | PlainMessage<EmailMessage> | undefined): boolean {
    return proto3.util.equals(EmailMessage, a, b);
  }
}

/**
 * @generated from message protogen.TextMessage
 */
export class TextMessage extends Message<TextMessage> {
  /**
   * Lets use this instead of "ID"
   *
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string conversationRef = 2;
   */
  conversationRef = "";

  /**
   * @generated from field: string senderRef = 3;
   */
  senderRef = "";

  /**
   * @generated from field: string content = 4;
   */
  content = "";

  /**
   * @generated from field: int64 timestampSec = 5;
   */
  timestampSec = protoInt64.zero;

  /**
   * @generated from field: repeated protogen.Attachment attachments = 6;
   */
  attachments: Attachment[] = [];

  /**
   * @generated from field: protogen.TextMessageStatus status = 7;
   */
  status = TextMessageStatus.STATUS_UNSPECIFIED;

  /**
   * These are suggestions for an individual message.
   *
   * @generated from field: protogen.DocumentSuggestion documentSuggestion = 9;
   */
  documentSuggestion?: DocumentSuggestion;

  /**
   * @generated from field: protogen.Suggestions sessionSuggestions = 11;
   */
  sessionSuggestions?: Suggestions;

  /**
   * @generated from field: repeated protogen.Task taskBookmarks = 12;
   */
  taskBookmarks: Task[] = [];

  /**
   * Only present when it has not been sent yet.
   *
   * @generated from field: int64 scheduledSecUTC = 13;
   */
  scheduledSecUTC = protoInt64.zero;

  constructor(data?: PartialMessage<TextMessage>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TextMessage";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "conversationRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "senderRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "content", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "timestampSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "attachments", kind: "message", T: Attachment, repeated: true },
    { no: 7, name: "status", kind: "enum", T: proto3.getEnumType(TextMessageStatus) },
    { no: 9, name: "documentSuggestion", kind: "message", T: DocumentSuggestion },
    { no: 11, name: "sessionSuggestions", kind: "message", T: Suggestions },
    { no: 12, name: "taskBookmarks", kind: "message", T: Task, repeated: true },
    { no: 13, name: "scheduledSecUTC", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TextMessage {
    return new TextMessage().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TextMessage {
    return new TextMessage().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TextMessage {
    return new TextMessage().fromJsonString(jsonString, options);
  }

  static equals(a: TextMessage | PlainMessage<TextMessage> | undefined, b: TextMessage | PlainMessage<TextMessage> | undefined): boolean {
    return proto3.util.equals(TextMessage, a, b);
  }
}

/**
 * @generated from message protogen.PhoneCall
 */
export class PhoneCall extends Message<PhoneCall> {
  /**
   * @generated from field: string ref = 1;
   */
  ref = "";

  /**
   * @generated from field: string callerRef = 2;
   */
  callerRef = "";

  /**
   * @generated from field: repeated string recipientRefs = 3;
   */
  recipientRefs: string[] = [];

  /**
   * @generated from field: int64 creationSec = 4;
   */
  creationSec = protoInt64.zero;

  /**
   * @generated from field: int64 lastUpdatedSec = 5;
   */
  lastUpdatedSec = protoInt64.zero;

  /**
   * Should be an enum, but this is volatile at the moment.
   *
   * @generated from field: string status = 6;
   */
  status = "";

  /**
   * @generated from field: protogen.PhoneCall.State state = 7;
   */
  state = PhoneCall_State.UNSPECIFIED;

  /**
   * @generated from field: bool isIncoming = 8;
   */
  isIncoming = false;

  /**
   * @generated from field: repeated string events = 9;
   */
  events: string[] = [];

  /**
   * @generated from field: bool transcriptionEnabled = 10;
   */
  transcriptionEnabled = false;

  constructor(data?: PartialMessage<PhoneCall>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.PhoneCall";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "ref", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "callerRef", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "recipientRefs", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 4, name: "creationSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 5, name: "lastUpdatedSec", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 6, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "state", kind: "enum", T: proto3.getEnumType(PhoneCall_State) },
    { no: 8, name: "isIncoming", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 9, name: "events", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 10, name: "transcriptionEnabled", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): PhoneCall {
    return new PhoneCall().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): PhoneCall {
    return new PhoneCall().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): PhoneCall {
    return new PhoneCall().fromJsonString(jsonString, options);
  }

  static equals(a: PhoneCall | PlainMessage<PhoneCall> | undefined, b: PhoneCall | PlainMessage<PhoneCall> | undefined): boolean {
    return proto3.util.equals(PhoneCall, a, b);
  }
}

/**
 * @generated from enum protogen.PhoneCall.State
 */
export enum PhoneCall_State {
  /**
   * @generated from enum value: STATE_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: STATE_ACTIVE = 1;
   */
  ACTIVE = 1,

  /**
   * @generated from enum value: STATE_COMPLETED = 2;
   */
  COMPLETED = 2,

  /**
   * @generated from enum value: STATE_MISSED = 3;
   */
  MISSED = 3,

  /**
   * @generated from enum value: STATE_FAILED = 4;
   */
  FAILED = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(PhoneCall_State)
proto3.util.setEnumType(PhoneCall_State, "protogen.PhoneCall.State", [
  { no: 0, name: "STATE_UNSPECIFIED" },
  { no: 1, name: "STATE_ACTIVE" },
  { no: 2, name: "STATE_COMPLETED" },
  { no: 3, name: "STATE_MISSED" },
  { no: 4, name: "STATE_FAILED" },
]);

