import React, { MouseEvent, useState, useEffect, useRef, useMemo } from "react";
import useIsVisible from "components/hooks/useIsVisible";
import {
  useArchiveEmails,
  useGetEmailThread,
  useMarkEmailsUnreadStatus,
} from "services/email";
import Loading from "components/common/Loading";
import { EmailMessage } from "protogen/conversation_pb";
import {
  Alert,
  AlertTitle,
  Avatar,
  Box,
  IconButton,
  Paper,
  Typography,
  Divider,
  Button,
  Tooltip,
  IconButtonProps,
} from "@mui/material";
import DateDisplay from "../common/DateDisplay";
import WithDividers from "components/helpers/WithDividers";
import EditIcon from "@mui/icons-material/Edit";
import EmailCompose from "./EmailCompose";
import EmailLetter from "components/email/EmailLetter";
import GridPage from "components/layout/GridPage";
import useIsMobile from "components/hooks/useIsMobile";
import ConfirmationDialog, {
  useConfirmationDialog,
} from "components/common/ConfirmationDialog";
import { CalendarEvent } from "protogen/calendar_pb";
import { nowSec } from "../../types/calendars";
import { eventSubtitle } from "components/calendar/utils";
import ViewEventDialog from "components/calendar/ViewEventDialog";
import EditEventDialog from "components/calendar/EditEventDialog";
import { Suggestion, SuggestionActionsType } from "../activity/Suggestions";
import SuggestionActions, {
  SuggestionActionsHandle,
} from "../activity/SuggestionActions";
import InboxThreadHeader from "../inbox/InboxThreadHeader";
import { Bookmark, Forward, Reply, Trash2 } from "lucide-react";
import { styled } from "@mui/system";
import Collapse from "@mui/material/Collapse";
import BookmarkToTaskDialog, { fromEmail } from "../tasks/BookmarkToTaskDialog";
import LinkRouter from "../navigation/LinkRouter";
import HighlightProvider from "../context/HighlightProvider";
import { ReactComponent as MarkEmailUnread } from "../../icons/MarkEmailUnread.svg";
import { useNavigate } from "react-router";
import NotFound from "../NotFound";
import useTitle from "../hooks/useTitle";

const StyledIconButton = styled(IconButton)(() => ({
  padding: "8px",
  borderRadius: "100px",
  border: "2px solid #EAEBEC",
  background: "#FFF",
}));
const StyledAction = ({
  title,
  children,
  disabled,
  onClick,
}: { title: string } & IconButtonProps) => {
  return (
    <Tooltip
      title={title}
      placement="top"
      enterDelay={300}
      slotProps={{
        popper: {
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, -10],
              },
            },
          ],
        },
      }}
    >
      <StyledIconButton disabled={disabled} onClick={onClick}>
        {children}
      </StyledIconButton>
    </Tooltip>
  );
};

const QuickActions = ({
  handleReply,
  handleForward,
}: {
  handleReply: (e: MouseEvent<HTMLButtonElement>) => void;
  handleForward: (e: MouseEvent<HTMLButtonElement>) => void;
}) => {
  return (
    <Box display={"flex"} flexDirection={"row"} gap={"8px"}>
      <Button
        variant="outlined"
        sx={{
          color: "#6B6E7B",
          height: "40px",
          border: "1px solid #D4D4D4",
          paddingLeft: "20px",
          paddingRight: "20px",
          "&:hover": {
            borderColor: "#6B6E7B",
          },
        }}
        endIcon={<Reply size={20} color="#6B6E7B" />}
        onClick={handleReply}
      >
        Reply
      </Button>
      <Button
        variant="outlined"
        sx={{
          color: "#6B6E7B",
          height: "40px",
          border: "1px solid #D4D4D4",
          paddingLeft: "20px",
          paddingRight: "20px",
          "&:hover": {
            borderColor: "#6B6E7B",
          },
        }}
        endIcon={<Forward size={20} color="#6B6E7B" />}
        onClick={handleForward}
      >
        Forward
      </Button>
    </Box>
  );
};

const BookmarkManager = ({
  familyRef,
  email,
  refresh,
}: {
  familyRef: string;
  email: EmailMessage;
  refresh: () => void;
}) => {
  const isMobile = useIsMobile();
  const [open, setOpen] = useState(false);
  let actions = null;
  if (email.taskBookmarks.length > 0) {
    actions = (
      <Box display={"flex"} flexDirection="column">
        {email.taskBookmarks.map((bookmark) => (
          <Box key={bookmark.ref}>
            <IconButton onClick={() => setOpen(true)}>
              <Bookmark size={20} color="#198282" fill={"#198282"} />
            </IconButton>
            <Typography variant="bodySmall" display={"inline"}>
              Saved to{" "}
              <LinkRouter
                inline={true}
                sx={{ color: "#198282", fontWeight: 600 }}
                targetNew={!isMobile}
                to={`/tasks/${encodeURIComponent(bookmark.ref)}`}
              >
                "{bookmark.title}"
              </LinkRouter>
            </Typography>
          </Box>
        ))}
      </Box>
    );
  } else if (email.taskBookmarks.length === 0) {
    actions = (
      <Button
        variant="text"
        onClick={() => setOpen(true)}
        sx={{
          height: "unset",
          color: "#6B6E7B",
          marginLeft: "5px",
        }}
        startIcon={<Bookmark size={20} color="#6B6E7B" />}
      >
        Save to task
      </Button>
    );
  }

  return (
    <>
      {actions}
      <BookmarkToTaskDialog
        familyRef={familyRef}
        open={open}
        onClose={() => {
          setOpen(false);
          refresh();
        }}
        entry={fromEmail(email)}
      />
    </>
  );
};

const EventPill = ({
  event,
  onClick,
}: {
  event: CalendarEvent;
  onClick: () => void;
}) => {
  const isMobile = useIsMobile();
  const now = nowSec();
  const isPast =
    (!event.endSec && now > event.startSec) ||
    (event.endSec && now > event.endSec);
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      gap={"8px"}
      onClick={onClick}
      sx={{
        cursor: "pointer",
        borderRadius: "12px",
        padding: isMobile ? "12px 16px" : "20px 24px",
        ...(isPast
          ? {
              border: "1px solid #EAEBEC",
              background: "#FAF9FA",
            }
          : {
              background: "#F3F7F6",
            }),
      }}
    >
      <Box display="flex" flexDirection="column" gap="4px" flex={1}>
        <Box
          sx={{
            color: "text.primary",
            fontSize: "16px",
            fontWeight: 700,
          }}
        >
          {event.title}
        </Box>
        <Box
          sx={{
            color: "text.secondary",
            fontSize: "14px",
            fontWeight: 500,
          }}
        >
          {eventSubtitle(event, true)}
        </Box>
      </Box>
    </Box>
  );
};

type EmailRowProps = {
  email: EmailMessage;
  initialExpanded: boolean;
  persistExpanded: boolean;
  onReply: (e: EmailMessage, isForward: boolean) => void;
  refresh: () => void;
  calendarEvents: Map<string, CalendarEvent>;
  selectEvent: (eventRef: string) => void;
  actions: SuggestionActionsType;
  showQuickActions: boolean;
  familyRef?: string;
  showSubject: boolean;
  onArchived?: () => void;
};
const EmailRow = ({
  email,
  initialExpanded,
  persistExpanded,
  onReply,
  refresh,
  calendarEvents,
  selectEvent,
  actions,
  showQuickActions,
  familyRef,
  showSubject,
  onArchived,
}: EmailRowProps) => {
  const isMobile = useIsMobile();
  const [hovering, setHovering] = useState(false);
  const [open, setOpen] = useState(initialExpanded);
  const [editing, setEditing] = useState(initialExpanded && email.isDraft);
  const confirmState = useConfirmationDialog();
  const { request: unreadRequest, loading: unreadLoading } =
    useMarkEmailsUnreadStatus();
  const { request: archiveRequest, loading: archiveLoading } =
    useArchiveEmails();
  const loading = unreadLoading || archiveLoading;
  const handleReply = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onReply(email, false);
  };
  const handleForward = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    onReply(email, true);
  };
  // We memoize the initial draft being passed into the editor since we don't want the editor
  // to re-render on every refresh.  All further state is handled internally in the editor,
  // including update network calls to the server.
  const initialDraft = useMemo(() => email, []);
  useEffect(() => {
    if (initialExpanded) {
      setOpen(true);
    }
  }, [initialExpanded]);
  const markUnread = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!loading) {
      await unreadRequest({ emailRefs: [email.ref], markAsRead: false });
      refresh();
    }
  };
  const archiveEmail = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (!loading) {
      confirmState.openDialog(async () => {
        await archiveRequest({ emailRefs: [email.ref] });
        onArchived?.();
        refresh();
      });
    }
  };
  const emailTime = (
    <DateDisplay
      date={new Date(Number(email.timestampSec) * 1000)}
      color="#475467"
      sx={{
        fontSize: "13px",
        fontStyle: "italic",
      }}
    />
  );
  const emailActions = (
    <Box
      display={"flex"}
      flexDirection={"row"}
      gap={"8px"}
      justifyContent={isMobile ? "space-between" : undefined}
      padding={isMobile ? "10px 50px 10px" : undefined}
    >
      {open && email.isDraft && !isMobile && (
        <EditIcon
          onClick={() => setEditing(true)}
          sx={{
            height: "20px",
            width: "20px",
            marginLeft: "10px",
            cursor: "pointer",
          }}
        />
      )}
      {open && !email.isDraft && (
        <>
          <StyledAction
            disabled={loading}
            onClick={archiveEmail}
            title={"Delete"}
          >
            <Trash2 height="20px" width="20px" stroke="#198282" />
          </StyledAction>
          <StyledAction
            disabled={loading}
            onClick={markUnread}
            title={"Mark unread"}
          >
            <MarkEmailUnread
              style={{
                height: "20px",
                width: "20px",
              }}
            />
          </StyledAction>
          <StyledAction onClick={handleReply} title={"Reply"}>
            <Reply height="20px" width="20px" stroke="#198282" />
          </StyledAction>
          <StyledAction onClick={handleForward} title={"Forward"}>
            <Forward height="20px" width="20px" stroke="#198282" />
          </StyledAction>
        </>
      )}
    </Box>
  );

  // If editing...open edit area here.
  if (editing) {
    return (
      <Box
        sx={{
          padding: "24px 32px 0 32px",
          background: "#FFF",
          marginBottom: "24px",
          scrollMarginBottom: "20px",
          ...(hovering ? { backgroundColor: "#e8f4f8" } : {}),
          ...(isMobile
            ? {
                borderTop: "1px solid #D4D4D4",
              }
            : {
                border: "1px solid #D4D4D4",
                borderRadius: "12px",
              }),
        }}
      >
        <EmailCompose
          setHovering={setHovering}
          onClose={() => {
            setOpen(false);
            setEditing(false);
          }}
          minHeight={"200px"}
          onSent={() => {
            setEditing(false);
            refresh();
          }}
          onRemove={() => {
            setEditing(false);
            setOpen(false);
            onArchived?.();
          }}
          initialDraft={initialDraft}
          inlineView={true}
        />
      </Box>
    );
  }
  return (
    <Paper
      elevation={0}
      sx={{
        display: "flex",
        flexDirection: "column",
        padding: isMobile ? "0px 24px" : undefined,
      }}
    >
      <Box
        display="flex"
        flexDirection="row"
        justifyContent={"space-between"}
        gap="15px"
        alignItems="start"
        sx={{
          ...(persistExpanded ? {} : { cursor: "pointer" }),
        }}
        onClick={() => !persistExpanded && setOpen((o) => !o)}
      >
        <Box
          display="flex"
          flexDirection="row"
          gap="12px"
          alignItems="start"
          flex={1}
          marginTop={!isMobile ? "9px" : undefined}
        >
          <Box sx={{ flexGrow: 0, flexShrink: 0, flexBasis: "40px" }}>
            <Avatar sx={{ height: 40, width: 40, fontSize: "16px" }}>
              {email.senderDisplayName[0]}
            </Avatar>
          </Box>
          <Box display="flex" flexDirection="column" sx={{ width: "100%" }}>
            <Box
              display="flex"
              flexDirection="row"
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Typography variant="bodyHeavy" sx={{ opacity: ".8" }}>
                {email.isDraft && (
                  <span
                    style={{
                      color: "#C14743",
                      marginRight: "4px",
                    }}
                  >
                    [draft]
                  </span>
                )}
                {email.senderDisplayName}
                {showSubject && (
                  <Typography variant="body" display={"inline"}>
                    {" "}
                    &middot; {email.subject}
                  </Typography>
                )}
              </Typography>
              {isMobile &&
                (email.isDraft ? (
                  <EditIcon
                    onClick={() => setEditing(true)}
                    sx={{
                      height: "20px",
                      width: "20px",
                      cursor: "pointer",
                    }}
                  />
                ) : (
                  emailTime
                ))}
            </Box>
            {!open && (
              <div
                style={{
                  wordBreak: "break-word",
                  overflow: "hidden",
                  maxHeight: "40px",
                  fontSize: "14px",
                }}
              >
                {email.text}
              </div>
            )}
            {open && (
              <Typography variant="bodySmall" sx={{ color: "#616161" }}>
                to {email.toAddresses.join(", ")}
                {email.ccAddresses.length > 0 && (
                  <>, cc {email.ccAddresses.join(", ")}</>
                )}
                {email.bccAddresses.length > 0 && (
                  <>, bcc {email.bccAddresses.join(", ")}</>
                )}
              </Typography>
            )}
          </Box>
        </Box>
        {!isMobile && (
          <Box display="flex" flexDirection="column" alignItems="end" gap="6px">
            {emailTime}
            {emailActions}
          </Box>
        )}
      </Box>
      {/* Or non-standard subject */}
      <Collapse in={open}>
        {isMobile && !email.isDraft && <Box>{emailActions}</Box>}
        <Box
          sx={{
            marginTop: "10px",
            ...(!isMobile
              ? {
                  marginLeft: "55px", // Avatar width + Gap
                }
              : {}),
            cursor: email.isDraft ? "pointer" : undefined,
          }}
          onClick={email.isDraft ? () => setEditing(true) : undefined}
        >
          {email.eventRefs.map((ref) => {
            const event = calendarEvents.get(ref);
            if (!event) {
              return null;
            }
            return (
              <EventPill
                key={ref}
                event={event}
                onClick={() => selectEvent(event.ref)}
              />
            );
          })}
          <HighlightProvider
            familyRef={familyRef}
            entityRef={email.ref}
            entityType={"email"}
          >
            <EmailLetter email={email} />
          </HighlightProvider>
          {email.threadSuggestions?.hasSuggestions && (
            <Box>
              <Suggestion
                disabled={!familyRef}
                suggestions={email.threadSuggestions}
                entityRef={email.ref}
                entityType="email"
                defaultOpen={true}
                actions={actions}
                warningMessage={
                  !familyRef
                    ? "Label this email with a family to suggest tasks, events, and facts."
                    : undefined
                }
              />
            </Box>
          )}
          <Box
            display={"flex"}
            flexDirection={"column"}
            gap={"16px"}
            padding={"12px 0"}
            alignItems={"start"}
          >
            {!email.isDraft && familyRef && (
              <BookmarkManager
                familyRef={familyRef}
                email={email}
                refresh={refresh}
              />
            )}
          </Box>
        </Box>
        {showQuickActions && (
          <QuickActions
            handleReply={handleReply}
            handleForward={handleForward}
          />
        )}
      </Collapse>
      <ConfirmationDialog
        title="Delete email"
        noIcon={true}
        content="Are you sure you want to delete this email?"
        {...confirmState.dialogProps}
      />
    </Paper>
  );
};

type ReplyState = {
  replyTo: EmailMessage | null;
  isForward: boolean;
};

type ThreadSectionProps = {
  initialEmailRef: string;
  emails: EmailMessage[];
  refresh: () => Promise<void>;
  calendarEvents: Map<string, CalendarEvent>;
  familyRef?: string;
};

const ThreadSection = ({
  emails,
  refresh,
  initialEmailRef,
  calendarEvents,
  familyRef,
}: ThreadSectionProps) => {
  const isMobile = useIsMobile();
  const navigate = useNavigate();
  const composeRef = useRef<HTMLDivElement>();
  const [emailState, setEmailState] = useState<ReplyState>({
    replyTo: null,
    isForward: false,
  });
  const actionsRef = useRef<SuggestionActionsHandle | null>(null);
  const [viewModalOpen, setViewModalOpen] = useState(false);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  useEffect(() => {
    if (emailState.isForward || emailState.replyTo) {
      setTimeout(() => {
        composeRef.current?.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "start",
        });
      }, 100);
    }
  }, [emailState.isForward, emailState.replyTo]);
  useEffect(() => {
    setEmailState({
      replyTo: null,
      isForward: false,
    });
  }, [initialEmailRef]);

  // Handle new drafts/sent messages/deletions when the edit modal is closed.
  const onClose = async () => {
    await refresh();
    setEmailState({
      replyTo: null,
      isForward: false,
    });
  };
  const onEnableEdit = (eventRef: string) => {
    setSelectedEvent(eventRef);
    setViewModalOpen(false);
    setEditModalOpen(true);
  };
  const selectEvent = (eventRef: string) => {
    setViewModalOpen(true);
    setSelectedEvent(eventRef);
  };
  const onEdited = () => {
    refresh();
    setEditModalOpen(false);
  };
  const showSubject = (e: EmailMessage) => {
    const firstEmail = emails[0];
    if (firstEmail.ref === e.ref) return false;
    const cleanSubject = e.subject.replace(/^(RE:|re:|FWD:|fwd:)/i, "").trim();
    return cleanSubject !== firstEmail.subject.trim();
  };
  const lastEmail = emails.length > 0 ? emails[emails.length - 1] : undefined;
  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <WithDividers>
        {emails.map((e, i) =>
          // Don't show the last email if it's a draft and we're replying to it already.
          (emailState.isForward || emailState.replyTo) &&
          i === emails.length - 1 &&
          e?.isDraft ? null : (
            <EmailRow
              key={e.ref}
              email={e}
              initialExpanded={
                initialEmailRef === e.ref || i === emails.length - 1
              }
              persistExpanded={i === emails.length - 1}
              onReply={(e, f) => {
                if (lastEmail?.isDraft) {
                  return;
                }
                setEmailState({
                  replyTo: e,
                  isForward: f,
                });
              }}
              refresh={refresh}
              calendarEvents={calendarEvents}
              selectEvent={selectEvent}
              actions={{
                suggestTask: (t) => actionsRef.current?.suggestTask(t),
                suggestFact: (f) => actionsRef.current?.suggestFact(f),
                suggestEvent: (e) => actionsRef.current?.suggestEvent(e),
              }}
              showQuickActions={
                i === emails.length - 1 && !e.isDraft && !emailState.replyTo
              }
              familyRef={familyRef}
              showSubject={showSubject(e)}
              onArchived={() => {
                if (emails.length <= 1) {
                  navigate(-1);
                }
                refresh();
              }}
            />
          ),
        )}
      </WithDividers>
      {emailState.replyTo && (
        <>
          <Divider />
          <Box
            ref={composeRef}
            sx={{
              padding: "24px 32px 0 32px",
              background: "#FFF",
              marginBottom: "24px",
              scrollMarginBottom: "20px",
              ...(isMobile
                ? {
                    borderTop: "1px solid #D4D4D4",
                  }
                : {
                    border: "1px solid #D4D4D4",
                    borderRadius: "12px",
                  }),
            }}
          >
            <EmailCompose
              onClose={onClose}
              minHeight={"200px"}
              inReplyTo={emailState.replyTo}
              onSent={onClose}
              onRemove={() => {
                if (emails.length <= 1) {
                  navigate(-1);
                } else {
                  onClose();
                }
              }}
              isForward={emailState.isForward}
              inlineView={true}
            />
          </Box>
        </>
      )}
      <ViewEventDialog
        open={viewModalOpen}
        onClose={(r) => {
          if (r) {
            refresh();
          }
          setViewModalOpen(false);
        }}
        primaryAction={onEnableEdit}
        eventRef={selectedEvent}
      />
      <EditEventDialog
        open={editModalOpen}
        onClose={() => setEditModalOpen(false)}
        onEdited={onEdited}
        eventRef={selectedEvent}
      />
      <SuggestionActions ref={actionsRef} familyRef={familyRef || ""} />
    </Box>
  );
};

interface EmailThreadProps {
  emailRef: string;
  onThreadUpdates: () => void;
}

export default ({ emailRef, onThreadUpdates }: EmailThreadProps) => {
  const { isVisible } = useIsVisible({});
  const [emails, setEmails] = useState<EmailMessage[]>([]);
  const [calendarEvents, setCalendarEvents] = useState<
    Map<string, CalendarEvent>
  >(new Map([]));
  useTitle(emails?.length ? emails[0].subject : "Inbox / Email");
  const { request, data, errorCode } = useGetEmailThread((r) => {
    // Don't thrash the state when it's just image URLs changing.
    setEmails((preValue) => {
      const prevMap = new Map(preValue.map((i) => [i.ref, i]));
      return r.emails.map((e) => {
        const prev = prevMap.get(e.ref);
        return prev &&
          prev.timestampSec === e.timestampSec &&
          prev.taskBookmarks === e.taskBookmarks &&
          prev?.threadSuggestions === e.threadSuggestions
          ? prev
          : e;
      });
    });
    setCalendarEvents(new Map(r.calendarEvents.map((e) => [e.ref, e])));
  });

  useEffect(() => {
    request({ emailRef, markAsRead: true });
    const intervalId = setInterval(async () => {
      if (isVisible) {
        request({ emailRef, markAsRead: false });
      }
    }, 30000);

    return () => clearInterval(intervalId);
  }, [emailRef]);

  if (errorCode === 404) return <NotFound title={"Email not found"} />;
  if (!data) {
    return <Loading />;
  }

  if (!emails || emails.length === 0) {
    // Only happens when you delete a draft without other threads.
    return (
      <GridPage>
        <Alert severity="warning">
          <AlertTitle>Empty Thread</AlertTitle>
          There are no emails associated with this thread.
        </Alert>
      </GridPage>
    );
  }
  return (
    <Box
      sx={{ width: "100%", marginBottom: "24px" }}
      display="flex"
      flexDirection={"column"}
      gap={"20px"}
    >
      <InboxThreadHeader>
        <Typography
          variant="h3"
          sx={{
            color: "#101828",
            // Optimize for email subjects at the moment.
            fontSize: "20px",
            fontWeight: 500,
          }}
        >
          {emails[0].subject}
        </Typography>
      </InboxThreadHeader>
      <Divider />
      <ThreadSection
        initialEmailRef={emailRef}
        emails={emails}
        refresh={async () => {
          await request({ emailRef, markAsRead: false });
          onThreadUpdates();
        }}
        calendarEvents={calendarEvents}
        familyRef={data.familyRef || undefined}
      />
    </Box>
  );
};
