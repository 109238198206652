import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import {
  ListEventsRequest,
  ListEventsResponse_EventCalendar,
} from "protogen/calendar_service_pb";
import { EventNotice, EventNotice_NoticeType } from "protogen/calendar_pb";
import { useListMemberCalendarEvents } from "services/calendar";
import FlexPage from "components/layout/FlexPage";
import { defaultParametersV2, CalendarParametersV2 } from "types/calendars";
import { CalendarFilter } from "components/calendar/CustomCalendarSettings";
import FayeCalendar from "components/calendar/FayeCalendar";
import { createColorMap } from "components/calendar/utils";
import useIsMobile from "components/hooks/useIsMobile";
import { MemberCalendarTodos } from "components/calendar/CalendarTodos";
import useDateParams from "components/hooks/calendar/useDateParams";

const FAYE_CALENDAR = "_faye-events";

export default () => {
  const isMobile = useIsMobile();
  const defaultDate = useDateParams();
  const [enabledCalendars, setEnabledCalendars] = useState<
    CalendarFilter[] | undefined
  >(undefined);
  const [calendarColorMap, setCalendarColorMap] = useState<Record<
    string,
    [string, string]
  > | null>(null);
  const [events, setEvents] = useState<EventNotice[]>([]);
  const [params, setParams] = useState<CalendarParametersV2>({
    ...defaultParametersV2(),
  });
  const [memberCalendars, setMemberCalendars] = useState<
    ListEventsResponse_EventCalendar[] | null
  >();
  const { request: memberEventsRequest, loading } = useListMemberCalendarEvents(
    (r) => {
      if (!calendarColorMap) {
        setCalendarColorMap(
          createColorMap(
            (r?.calendars || []).map(
              (c) => `${c.integrationRef}|${c.calendarId}`,
            ),
          ),
        );
      }
      if (!memberCalendars) {
        setMemberCalendars(r?.calendars || []);
      }
    },
  );
  useEffect(() => {
    fetchEvents(defaultDate, true);
  }, []);

  const fetchEvents = async (newDate: Date, includeExternalEvents: boolean) => {
    const todayMonth = newDate.getMonth() + 1;
    setParams({
      ...params,
      month: todayMonth,
      year: newDate.getFullYear(),
      day: newDate.getDate(),
    });

    // For week view - there can be two months in a week
    const oneWeekLater = new Date(newDate);
    oneWeekLater.setDate(oneWeekLater.getDate() + 7);
    const oneWeekMonth = oneWeekLater.getMonth() + 1;
    let oneWeekYear = oneWeekLater.getFullYear();
    // Check if the month goes over December
    if (oneWeekMonth > 12) {
      oneWeekYear += 1;
    }

    const requests = [
      memberEventsRequest(
        new ListEventsRequest({
          ...params,
          familyRefs: undefined,
          month: todayMonth,
          year: newDate.getFullYear(),
          includeExternalEvents: includeExternalEvents,
        }),
      ),
    ];

    // This can occur on week view where months overlap
    if (todayMonth !== oneWeekMonth) {
      requests.push(
        memberEventsRequest(
          new ListEventsRequest({
            ...params,
            familyRefs: undefined,
            month: oneWeekMonth,
            year: oneWeekYear,
            includeExternalEvents: includeExternalEvents,
          }),
        ),
      );
    }
    const results = await Promise.all(requests);
    const allEvents = results.flatMap((result) => result?.eventNotices || []);

    setEvents((prevEvents) => [
      ...(!includeExternalEvents
        ? prevEvents.filter(
            (e) =>
              e.noticeType === EventNotice_NoticeType.NoticeType_EXTERNAL_EVENT,
          )
        : []),
      ...allEvents,
    ]);
  };
  return (
    <FlexPage
      leftAligned
      fullHeight
      sx={{
        padding: isMobile ? "16px 6px 0" : "16px 32px 0",
        maxWidth: "1400px",
      }}
    >
      {/* TODO: If at least one of the todos is not done, then... */}
      <Box
        sx={{
          display: "flex",
          height: "100%",
          flexDirection: "column",
          overflow: "hidden",
        }}
      >
        <MemberCalendarTodos />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            height: "10px",
            flexGrow: 1,
            padding: isMobile ? "0 20px" : "",
          }}
        >
          <FayeCalendar
            accountType="member"
            events={events.filter((e) => {
              if (!enabledCalendars) {
                return true;
              }
              const calSet = new Set(enabledCalendars?.map((c) => c.key) || []);
              if (
                e.noticeType ===
                EventNotice_NoticeType.NoticeType_EXTERNAL_EVENT
              ) {
                return calSet.has(
                  `${e.externalCalendarRef}|${e.externalCalendarId}`,
                );
              }
              return calSet.has(FAYE_CALENDAR);
            })}
            getEventStyles={(event) => {
              const colors =
                calendarColorMap?.[
                  `${event.externalCalendarRef}|${event.externalCalendarId}`
                ];
              return {
                backgroundColor: colors?.[0] || undefined,
                color: colors?.[1] || undefined,
              };
            }}
            calendarFilters={[
              ...(memberCalendars || [])
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((c) => ({
                  key: `${c.integrationRef}|${c.calendarId}`,
                  name: c.name,
                  color:
                    calendarColorMap?.[
                      `${c.integrationRef}|${c.calendarId}`
                    ][0],
                })),
              {
                name: "Faye Events",
                key: FAYE_CALENDAR,
              },
            ]}
            setEnabledFilters={(filters) => {
              setEnabledCalendars(filters);
            }}
            refreshEvents={async () => {
              fetchEvents(
                new Date(params.year, params.month - 1, params.day),
                true,
              );
            }}
            handleNavigate={async (d) => {
              fetchEvents(d, false);
            }}
            loading={loading}
          />
        </Box>
      </Box>
    </FlexPage>
  );
};
