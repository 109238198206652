// @generated by protoc-gen-es v1.2.0 with parameter "target=ts,import_extension=.ts"
// @generated from file protogen/auth_service.proto (package protogen, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3 } from "@bufbuild/protobuf";
import { AuthToken, CurrentUser } from "./auth_pb.ts";
import { AccountType } from "./common_pb.ts";

/**
 * @generated from message protogen.LoginRequest
 */
export class LoginRequest extends Message<LoginRequest> {
  /**
   * @generated from field: string username = 1;
   */
  username = "";

  /**
   * @generated from field: string password = 2;
   */
  password = "";

  /**
   * Pass along any active subscription so that we can avoid re-triggering the request flow.
   *
   * @generated from field: string subscription = 3;
   */
  subscription = "";

  constructor(data?: PartialMessage<LoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginRequest {
    return new LoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginRequest {
    return new LoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginRequest {
    return new LoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LoginRequest | PlainMessage<LoginRequest> | undefined, b: LoginRequest | PlainMessage<LoginRequest> | undefined): boolean {
    return proto3.util.equals(LoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.LoginResponse
 */
export class LoginResponse extends Message<LoginResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<LoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LoginResponse {
    return new LoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LoginResponse {
    return new LoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LoginResponse {
    return new LoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LoginResponse | PlainMessage<LoginResponse> | undefined, b: LoginResponse | PlainMessage<LoginResponse> | undefined): boolean {
    return proto3.util.equals(LoginResponse, a, b);
  }
}

/**
 * @generated from message protogen.RefreshTokenRequest
 */
export class RefreshTokenRequest extends Message<RefreshTokenRequest> {
  /**
   * @generated from field: string refreshToken = 1;
   */
  refreshToken = "";

  constructor(data?: PartialMessage<RefreshTokenRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RefreshTokenRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "refreshToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshTokenRequest {
    return new RefreshTokenRequest().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshTokenRequest | PlainMessage<RefreshTokenRequest> | undefined, b: RefreshTokenRequest | PlainMessage<RefreshTokenRequest> | undefined): boolean {
    return proto3.util.equals(RefreshTokenRequest, a, b);
  }
}

/**
 * @generated from message protogen.RefreshTokenResponse
 */
export class RefreshTokenResponse extends Message<RefreshTokenResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 2;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<RefreshTokenResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.RefreshTokenResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RefreshTokenResponse {
    return new RefreshTokenResponse().fromJsonString(jsonString, options);
  }

  static equals(a: RefreshTokenResponse | PlainMessage<RefreshTokenResponse> | undefined, b: RefreshTokenResponse | PlainMessage<RefreshTokenResponse> | undefined): boolean {
    return proto3.util.equals(RefreshTokenResponse, a, b);
  }
}

/**
 * @generated from message protogen.ResetPasswordRequest
 */
export class ResetPasswordRequest extends Message<ResetPasswordRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * @generated from field: string username = 2;
   */
  username = "";

  /**
   * @generated from field: string password = 3;
   */
  password = "";

  /**
   * Pass along any active subscription so that we can avoid re-triggering the request flow.
   *
   * @generated from field: string subscription = 4;
   */
  subscription = "";

  constructor(data?: PartialMessage<ResetPasswordRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ResetPasswordRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "username", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "password", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordRequest {
    return new ResetPasswordRequest().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordRequest | PlainMessage<ResetPasswordRequest> | undefined, b: ResetPasswordRequest | PlainMessage<ResetPasswordRequest> | undefined): boolean {
    return proto3.util.equals(ResetPasswordRequest, a, b);
  }
}

/**
 * @generated from message protogen.ResetPasswordResponse
 */
export class ResetPasswordResponse extends Message<ResetPasswordResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<ResetPasswordResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.ResetPasswordResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): ResetPasswordResponse {
    return new ResetPasswordResponse().fromJsonString(jsonString, options);
  }

  static equals(a: ResetPasswordResponse | PlainMessage<ResetPasswordResponse> | undefined, b: ResetPasswordResponse | PlainMessage<ResetPasswordResponse> | undefined): boolean {
    return proto3.util.equals(ResetPasswordResponse, a, b);
  }
}

/**
 * @generated from message protogen.InitiatePasswordResetRequest
 */
export class InitiatePasswordResetRequest extends Message<InitiatePasswordResetRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: protogen.InitiatePasswordResetRequest.Flow flow = 2;
   */
  flow = InitiatePasswordResetRequest_Flow.UNSPECIFIED;

  constructor(data?: PartialMessage<InitiatePasswordResetRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiatePasswordResetRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "flow", kind: "enum", T: proto3.getEnumType(InitiatePasswordResetRequest_Flow) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiatePasswordResetRequest {
    return new InitiatePasswordResetRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiatePasswordResetRequest {
    return new InitiatePasswordResetRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiatePasswordResetRequest {
    return new InitiatePasswordResetRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiatePasswordResetRequest | PlainMessage<InitiatePasswordResetRequest> | undefined, b: InitiatePasswordResetRequest | PlainMessage<InitiatePasswordResetRequest> | undefined): boolean {
    return proto3.util.equals(InitiatePasswordResetRequest, a, b);
  }
}

/**
 * @generated from enum protogen.InitiatePasswordResetRequest.Flow
 */
export enum InitiatePasswordResetRequest_Flow {
  /**
   * @generated from enum value: FLOW_UNSPECIFIED = 0;
   */
  UNSPECIFIED = 0,

  /**
   * @generated from enum value: FLOW_RESET_PASSWORD = 1;
   */
  RESET_PASSWORD = 1,

  /**
   * @generated from enum value: FLOW_SETUP_ACCOUNT = 2;
   */
  SETUP_ACCOUNT = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(InitiatePasswordResetRequest_Flow)
proto3.util.setEnumType(InitiatePasswordResetRequest_Flow, "protogen.InitiatePasswordResetRequest.Flow", [
  { no: 0, name: "FLOW_UNSPECIFIED" },
  { no: 1, name: "FLOW_RESET_PASSWORD" },
  { no: 2, name: "FLOW_SETUP_ACCOUNT" },
]);

/**
 * @generated from message protogen.InitiatePasswordResetResponse
 */
export class InitiatePasswordResetResponse extends Message<InitiatePasswordResetResponse> {
  constructor(data?: PartialMessage<InitiatePasswordResetResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiatePasswordResetResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiatePasswordResetResponse {
    return new InitiatePasswordResetResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiatePasswordResetResponse {
    return new InitiatePasswordResetResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiatePasswordResetResponse {
    return new InitiatePasswordResetResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiatePasswordResetResponse | PlainMessage<InitiatePasswordResetResponse> | undefined, b: InitiatePasswordResetResponse | PlainMessage<InitiatePasswordResetResponse> | undefined): boolean {
    return proto3.util.equals(InitiatePasswordResetResponse, a, b);
  }
}

/**
 * @generated from message protogen.InitiateMemberLoginRequest
 */
export class InitiateMemberLoginRequest extends Message<InitiateMemberLoginRequest> {
  /**
   * @generated from field: string email = 1;
   */
  email = "";

  /**
   * @generated from field: string phone = 2;
   */
  phone = "";

  /**
   * @generated from field: string redirectUrl = 3;
   */
  redirectUrl = "";

  constructor(data?: PartialMessage<InitiateMemberLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiateMemberLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "phone", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "redirectUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateMemberLoginRequest {
    return new InitiateMemberLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateMemberLoginRequest {
    return new InitiateMemberLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateMemberLoginRequest {
    return new InitiateMemberLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateMemberLoginRequest | PlainMessage<InitiateMemberLoginRequest> | undefined, b: InitiateMemberLoginRequest | PlainMessage<InitiateMemberLoginRequest> | undefined): boolean {
    return proto3.util.equals(InitiateMemberLoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.InitiateMemberLoginResponse
 */
export class InitiateMemberLoginResponse extends Message<InitiateMemberLoginResponse> {
  constructor(data?: PartialMessage<InitiateMemberLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.InitiateMemberLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InitiateMemberLoginResponse {
    return new InitiateMemberLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InitiateMemberLoginResponse {
    return new InitiateMemberLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InitiateMemberLoginResponse {
    return new InitiateMemberLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: InitiateMemberLoginResponse | PlainMessage<InitiateMemberLoginResponse> | undefined, b: InitiateMemberLoginResponse | PlainMessage<InitiateMemberLoginResponse> | undefined): boolean {
    return proto3.util.equals(InitiateMemberLoginResponse, a, b);
  }
}

/**
 * @generated from message protogen.MemberLoginRequest
 */
export class MemberLoginRequest extends Message<MemberLoginRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  /**
   * Pass along any active subscription so that we can avoid re-triggering the request flow.
   *
   * @generated from field: string subscription = 4;
   */
  subscription = "";

  constructor(data?: PartialMessage<MemberLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MemberLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "subscription", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberLoginRequest {
    return new MemberLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberLoginRequest {
    return new MemberLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberLoginRequest {
    return new MemberLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MemberLoginRequest | PlainMessage<MemberLoginRequest> | undefined, b: MemberLoginRequest | PlainMessage<MemberLoginRequest> | undefined): boolean {
    return proto3.util.equals(MemberLoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.MemberLoginResponse
 */
export class MemberLoginResponse extends Message<MemberLoginResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  /**
   * @generated from field: string redirectUrl = 4;
   */
  redirectUrl = "";

  constructor(data?: PartialMessage<MemberLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MemberLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
    { no: 4, name: "redirectUrl", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberLoginResponse {
    return new MemberLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberLoginResponse {
    return new MemberLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberLoginResponse {
    return new MemberLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MemberLoginResponse | PlainMessage<MemberLoginResponse> | undefined, b: MemberLoginResponse | PlainMessage<MemberLoginResponse> | undefined): boolean {
    return proto3.util.equals(MemberLoginResponse, a, b);
  }
}

/**
 * @generated from message protogen.TokenLoginRequest
 */
export class TokenLoginRequest extends Message<TokenLoginRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<TokenLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TokenLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenLoginRequest {
    return new TokenLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenLoginRequest {
    return new TokenLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenLoginRequest {
    return new TokenLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: TokenLoginRequest | PlainMessage<TokenLoginRequest> | undefined, b: TokenLoginRequest | PlainMessage<TokenLoginRequest> | undefined): boolean {
    return proto3.util.equals(TokenLoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.TokenLoginResponse
 */
export class TokenLoginResponse extends Message<TokenLoginResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<TokenLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.TokenLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): TokenLoginResponse {
    return new TokenLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): TokenLoginResponse {
    return new TokenLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): TokenLoginResponse {
    return new TokenLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: TokenLoginResponse | PlainMessage<TokenLoginResponse> | undefined, b: TokenLoginResponse | PlainMessage<TokenLoginResponse> | undefined): boolean {
    return proto3.util.equals(TokenLoginResponse, a, b);
  }
}

/**
 *  string accessToken = 1;
 *  string refreshToken = 2;
 *
 * @generated from message protogen.LogoutRequest
 */
export class LogoutRequest extends Message<LogoutRequest> {
  constructor(data?: PartialMessage<LogoutRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LogoutRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutRequest {
    return new LogoutRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutRequest {
    return new LogoutRequest().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutRequest | PlainMessage<LogoutRequest> | undefined, b: LogoutRequest | PlainMessage<LogoutRequest> | undefined): boolean {
    return proto3.util.equals(LogoutRequest, a, b);
  }
}

/**
 * @generated from message protogen.LogoutResponse
 */
export class LogoutResponse extends Message<LogoutResponse> {
  constructor(data?: PartialMessage<LogoutResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.LogoutResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): LogoutResponse {
    return new LogoutResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): LogoutResponse {
    return new LogoutResponse().fromJsonString(jsonString, options);
  }

  static equals(a: LogoutResponse | PlainMessage<LogoutResponse> | undefined, b: LogoutResponse | PlainMessage<LogoutResponse> | undefined): boolean {
    return proto3.util.equals(LogoutResponse, a, b);
  }
}

/**
 * @generated from message protogen.AcceptTermsOfServiceRequest
 */
export class AcceptTermsOfServiceRequest extends Message<AcceptTermsOfServiceRequest> {
  /**
   * @generated from field: protogen.AccountType accountType = 1;
   */
  accountType = AccountType.UNKNOWN;

  /**
   * @generated from field: int32 termsOfServiceVersion = 2;
   */
  termsOfServiceVersion = 0;

  constructor(data?: PartialMessage<AcceptTermsOfServiceRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AcceptTermsOfServiceRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accountType", kind: "enum", T: proto3.getEnumType(AccountType) },
    { no: 2, name: "termsOfServiceVersion", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptTermsOfServiceRequest {
    return new AcceptTermsOfServiceRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptTermsOfServiceRequest {
    return new AcceptTermsOfServiceRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptTermsOfServiceRequest {
    return new AcceptTermsOfServiceRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptTermsOfServiceRequest | PlainMessage<AcceptTermsOfServiceRequest> | undefined, b: AcceptTermsOfServiceRequest | PlainMessage<AcceptTermsOfServiceRequest> | undefined): boolean {
    return proto3.util.equals(AcceptTermsOfServiceRequest, a, b);
  }
}

/**
 * @generated from message protogen.AcceptTermsOfServiceResponse
 */
export class AcceptTermsOfServiceResponse extends Message<AcceptTermsOfServiceResponse> {
  constructor(data?: PartialMessage<AcceptTermsOfServiceResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AcceptTermsOfServiceResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AcceptTermsOfServiceResponse {
    return new AcceptTermsOfServiceResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AcceptTermsOfServiceResponse {
    return new AcceptTermsOfServiceResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AcceptTermsOfServiceResponse {
    return new AcceptTermsOfServiceResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AcceptTermsOfServiceResponse | PlainMessage<AcceptTermsOfServiceResponse> | undefined, b: AcceptTermsOfServiceResponse | PlainMessage<AcceptTermsOfServiceResponse> | undefined): boolean {
    return proto3.util.equals(AcceptTermsOfServiceResponse, a, b);
  }
}

/**
 * @generated from message protogen.AdvisorNuxLoginRequest
 */
export class AdvisorNuxLoginRequest extends Message<AdvisorNuxLoginRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<AdvisorNuxLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdvisorNuxLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdvisorNuxLoginRequest {
    return new AdvisorNuxLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdvisorNuxLoginRequest {
    return new AdvisorNuxLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdvisorNuxLoginRequest {
    return new AdvisorNuxLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: AdvisorNuxLoginRequest | PlainMessage<AdvisorNuxLoginRequest> | undefined, b: AdvisorNuxLoginRequest | PlainMessage<AdvisorNuxLoginRequest> | undefined): boolean {
    return proto3.util.equals(AdvisorNuxLoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.AdvisorNuxLoginResponse
 */
export class AdvisorNuxLoginResponse extends Message<AdvisorNuxLoginResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<AdvisorNuxLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.AdvisorNuxLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): AdvisorNuxLoginResponse {
    return new AdvisorNuxLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): AdvisorNuxLoginResponse {
    return new AdvisorNuxLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): AdvisorNuxLoginResponse {
    return new AdvisorNuxLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: AdvisorNuxLoginResponse | PlainMessage<AdvisorNuxLoginResponse> | undefined, b: AdvisorNuxLoginResponse | PlainMessage<AdvisorNuxLoginResponse> | undefined): boolean {
    return proto3.util.equals(AdvisorNuxLoginResponse, a, b);
  }
}

/**
 * @generated from message protogen.MemberNuxLoginRequest
 */
export class MemberNuxLoginRequest extends Message<MemberNuxLoginRequest> {
  /**
   * @generated from field: string token = 1;
   */
  token = "";

  constructor(data?: PartialMessage<MemberNuxLoginRequest>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MemberNuxLoginRequest";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "token", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberNuxLoginRequest {
    return new MemberNuxLoginRequest().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberNuxLoginRequest {
    return new MemberNuxLoginRequest().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberNuxLoginRequest {
    return new MemberNuxLoginRequest().fromJsonString(jsonString, options);
  }

  static equals(a: MemberNuxLoginRequest | PlainMessage<MemberNuxLoginRequest> | undefined, b: MemberNuxLoginRequest | PlainMessage<MemberNuxLoginRequest> | undefined): boolean {
    return proto3.util.equals(MemberNuxLoginRequest, a, b);
  }
}

/**
 * @generated from message protogen.MemberNuxLoginResponse
 */
export class MemberNuxLoginResponse extends Message<MemberNuxLoginResponse> {
  /**
   * @generated from field: protogen.AuthToken accessToken = 1;
   */
  accessToken?: AuthToken;

  /**
   * @generated from field: protogen.AuthToken refreshToken = 2;
   */
  refreshToken?: AuthToken;

  /**
   * @generated from field: protogen.CurrentUser currentUser = 3;
   */
  currentUser?: CurrentUser;

  constructor(data?: PartialMessage<MemberNuxLoginResponse>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "protogen.MemberNuxLoginResponse";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "accessToken", kind: "message", T: AuthToken },
    { no: 2, name: "refreshToken", kind: "message", T: AuthToken },
    { no: 3, name: "currentUser", kind: "message", T: CurrentUser },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): MemberNuxLoginResponse {
    return new MemberNuxLoginResponse().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): MemberNuxLoginResponse {
    return new MemberNuxLoginResponse().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): MemberNuxLoginResponse {
    return new MemberNuxLoginResponse().fromJsonString(jsonString, options);
  }

  static equals(a: MemberNuxLoginResponse | PlainMessage<MemberNuxLoginResponse> | undefined, b: MemberNuxLoginResponse | PlainMessage<MemberNuxLoginResponse> | undefined): boolean {
    return proto3.util.equals(MemberNuxLoginResponse, a, b);
  }
}

