import { createTheme, Theme } from "@mui/material/styles";

// override modules for material ui can be found in modules/mui-overrides.d.ts

let base = createTheme({});
const baseTheme: Theme = createTheme({
  typography: {
    fontFamily: "AlbertSans, SourceSerifPro, sans-serif",
    display: {
      fontFamily: "SourceSerifPro",
      [base.breakpoints.down("sm")]: {
        fontSize: "28px",
      },
      fontSize: "32px",
      fontWeight: "600",
      color: "#3D3D3D",
      lineHeight: "normal",
      letterSpacing: "-0.32px",
    },
    title: {
      fontFamily: "SourceSerifPro",
      [base.breakpoints.down("sm")]: {
        fontSize: "24px",
      },
      fontSize: "28px",
      fontWeight: "600",
      color: "#3D3D3D",
      lineHeight: "normal",
      letterSpacing: "-0.32px",
    },
    h1: {
      fontFamily: "AlbertSans",
      fontSize: "28px",
      fontWeight: "700",
      color: "#3D3D3D",
      lineHeight: "auto",
      letterSpacing: "-0.4px",
    },
    h1Serif: {
      fontFamily: "SourceSerifPro",
      fontSize: "28px",
      fontWeight: "600",
      color: "#3D3D3D",
      lineHeight: "auto",
      letterSpacing: "-0.32px",
    },
    h2: {
      fontFamily: "AlbertSans",
      fontSize: "24px",
      fontWeight: "700",
      color: "#3D3D3D",
      lineHeight: "22px",
      letterSpacing: "-0.4px",
    },
    h2Serif: {
      fontFamily: "SourceSerifPro",
      fontSize: "24px",
      fontWeight: "600",
      color: "#3D3D3D",
      lineHeight: "22px",
      letterSpacing: "-0.32px",
    },
    h3: {
      fontFamily: "AlbertSans",
      fontSize: "20px",
      fontWeight: "700",
      color: "#3D3D3D",
      lineHeight: "22px",
      letterSpacing: "-0.4px",
    },
    h3Serif: {
      fontFamily: "SourceSerifPro",
      fontSize: "20px",
      fontWeight: "600",
      color: "#3D3D3D",
      lineHeight: "22px",
      letterSpacing: "-0.32px",
    },
    h4: {
      fontFamily: "AlbertSans",
      fontSize: "18px",
      fontWeight: "700",
      color: "#3D3D3D",
      lineHeight: "130%",
      letterSpacing: "-0.4px",
    },
    h4Serif: {
      fontFamily: "SourceSerifPro",
      fontSize: "18px",
      fontWeight: "600",
      color: "#3D3D3D",
      lineHeight: "130%",
      letterSpacing: "-0.32px",
      display: "block", // Line height behaves differently on display block/inline block.  Material renders typography as spans.
    },
    body: {
      fontFamily: "AlbertSans",
      fontSize: "16px",
      fontWeight: "500",
      color: "#616161",
      lineHeight: "141%",
      letterSpacing: "-0.4px",
      display: "block", // Line height behaves differently on display block/inline block.  Material renders typography as spans.
    },
    bodyHeavy: {
      fontFamily: "AlbertSans",
      fontSize: "16px",
      fontWeight: "700",
      color: "#3D3D3D",
      lineHeight: "141%",
      letterSpacing: "-0.4px",
      display: "block", // Line height behaves differently on display block/inline block.  Material renders typography as spans.
    },
    bodySmall: {
      fontFamily: "AlbertSans",
      fontSize: "14px",
      fontWeight: "500",
      color: "#3D3D3D",
      lineHeight: "140%",
      letterSpacing: "-0.4px",
      display: "block", // Line height behaves differently on display block/inline block.  Material renders typography as spans.
    },
    bodySmallHeavy: {
      fontFamily: "AlbertSans",
      fontSize: "14px",
      fontWeight: "700",
      color: "#3D3D3D",
      lineHeight: "140%",
      letterSpacing: "-0.4px",
      display: "block", // Line height behaves differently on display block/inline block.  Material renders typography as spans.
    },
    caption: {
      fontFamily: "AlbertSans",
      fontSize: "15px",
      fontWeight: "500",
      color: "#3D3D3D",
      lineHeight: "140%",
      letterSpacing: "-0.4px",
      display: "block", // Line height behaves differently on display block/inline block.  Material renders typography as spans.
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: `
        @font-face {
          font-family: 'AlbertSans';
          src: url('/assets/fonts/AlbertSans-VariableFont_wght.ttf') format('truetype');
          /* Add more src declarations for different font formats if available */
          font-weight: 100 1000;
          font-style: normal;
        }
        @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 200;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-ExtraLight.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-ExtraLight.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 200;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-ExtraLightIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-ExtraLightIt.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 300;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Light.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Light.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 300;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-LightIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-LightIt.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 400;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Regular.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Regular.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 400;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-It.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-It.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 600;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Semibold.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Semibold.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 600;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-SemiboldIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-SemiboldIt.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 700;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Bold.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Bold.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 700;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-BoldIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-BoldIt.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 900;
          font-style: normal;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-Black.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-Black.otf.woff') format('woff');
      }

      @font-face{
          font-family: 'SourceSerifPro';
          font-weight: 900;
          font-style: italic;
          font-stretch: normal;
          src: url('/assets/fonts/SourceSerifPro/WOFF2/TTF/SourceSerifPro-BlackIt.ttf.woff2') format('woff2'),
               url('/assets/fonts/SourceSerifPro/WOFF/OTF/SourceSerifPro-BlackIt.otf.woff') format('woff');
      }
      `,
    },
    MuiButtonGroup: {
      styleOverrides: {
        root: {
          backgroundColor: "#F6F6F6",
          borderRadius: "100px",
          overflow: "hidden",
          padding: "3px 3px",
          display: "flex",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "contained",
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: "outlinedSecondary" },
          style: {
            color: "#198282",
            border: "2px solid #D4D4D4",
            "&:hover": {
              border: "2px solid #D4D4D4",
              backgroundColor: "inherit",
            },
          },
        },
        {
          props: { variant: "buttonGroup" },
          style: {
            color: "#3D3D3D",
            border: "none",
            fontWeight: "500",
            borderRadius: "100px !important",
            height: "100%",
            "&:hover": {
              border: "none",
              backgroundColor: "inherit",
            },
            "& .MuiTouchRipple-root": {
              color: "transparent !important",
            },
          },
        },
        {
          props: { variant: "buttonGroupSelected" },
          style: {
            color: "#3D3D3D",
            backgroundColor: "#FFF",
            height: "100%",
            fontWeight: "500",
            borderRadius: "100px !important",
            boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.15)",
            "&:hover": {
              border: "none",
              borderRadius: "100px !important",
              boxShadow: "0px 1px 6px 0px rgba(0, 0, 0, 0.15)",
              backgroundColor: "#FFF",
            },
            "& .MuiTouchRipple-root": {
              color: "transparent !important",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          fontWeight: 600,
          borderRadius: "100px",
          textTransform: "none",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
          "& .MuiButton-endIcon": {
            marginRight: "0px !important",
          },
          "&.MuiButton-outlined": {
            "&:active": {
              backgroundColor: "#C8DBD7",
            },
          },
          "&.MuiButton-contained": {
            "&:active": {
              backgroundColor: "#3C5556",
            },
          },
        },
        // This is our "Primary button"
        // We only have two sizes, primary/small.
        sizeMedium: {
          height: "48px",
          padding: "8px 24px",
          fontSize: "16px",
          fontWeight: "600",
        },
        sizeSmall: {
          height: "40px",
          padding: "8px 24px",
          fontSize: "14px",
          fontWeight: "600",
          lineHeight: "normal",
        },
        // This maps to primary in the design
        contained: {
          "&:hover": {
            backgroundColor: "#198282",
          },
        },
        // This maps to secondary in the design
        outlined: {
          border: "2px solid #198282",
          "&:hover": {
            border: "2px solid #198282",
            backgroundColor: "inherit",
          },
        },
        // This maps to tertiary in the design
        text: {
          padding: "0px !important",
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          backgroundColor: "white",
          padding: "16px 6px",
          gap: "8px",
          color: "#6B6E7B",
          borderColor: "#D0D5DD",

          "& .MuiChip-deleteIcon": {
            color: "#8E9598",
            width: "20px",
            height: "20px",
          },

          "& .MuiChip-label": {
            fontWeight: "500",
            fontSize: "15px",
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginTop: "8px",
          borderRadius: "10px",
          boxShadow: "none",
          border: "2px solid #E2E3E4",
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          padding: "0",
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          padding: "12px",
          fontWeight: "500",
          "&.Mui-selected": {},
          "&.Mui-focused": {
            backgroundColor: "white",
          },
          "&:hover": {
            backgroundColor: "white",
          },
          "&.Mui-focusVisible": {
            backgroundColor: "#E4ECEC !important",
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&:hover": {
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          padding: "0px",

          "&:hover": {
            "@media (hover: none)": {
              backgroundColor: "transparent",
            },
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          minWidth: "24px !important",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontFamily: "AlbertSans",
          fontSize: "16px",
          color: "#6B6E7B",
          fontWeight: 500,
          lineHeight: "22px",
          top: "18px",
          left: "20px",
          transform: "None",
          "&.Mui-focused": {
            // All these need to be repeated to override
            fontFamily: "AlbertSans",
            color: "#6B6E7B",
            fontWeight: 500,
            fontSize: "12px",
            top: "6px",
            transform: "None",
          },
          "&.MuiInputLabel-filled": {
            fontFamily: "AlbertSans",
            color: "#6B6E7B",
            fontWeight: 500,
            fontSize: "12px",
            top: "6px",
            left: "20px",
          },
          "&.MuiInputLabel-shrink": {
            fontFamily: "AlbertSans",
            color: "#6B6E7B",
            fontWeight: 500,
            fontSize: "12px",
            top: "6px",
            transform: "None",
            left: "20px",
          },
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          minHeight: "60px",
          padding: "0px",
          paddingRight: "12px",
          fontWeight: 500,
          "&:hover .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #D4D4D4",
            fontWeight: 500,
          },
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "2px solid #EAEBEC",
            fontWeight: 500,
          },
          "& .MuiInputAdornment-root": {
            paddingLeft: "12px",
            paddingTop: "12px",
          },
        },
        input: {
          marginTop: "16px",
          padding: "0px 20px",
          fontWeight: 500,
        },
        inputMultiline: {
          marginTop: "20px",
          fontWeight: 500,
        },
        notchedOutline: {
          top: "0px",
          border: "1px solid #D4D4D4",
          borderRadius: "8px",
          fontWeight: 500,
          "& legend": {
            display: "none",
          },
        },
      },
    },

    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "white",
          color: "#3D3D3D",
          fontSize: "13px",
          border: "1px solid #D4D4D4",
          boxShadow: "0px 2px 8px 0px rgba(151, 93, 51, 0.07)",
          borderRadius: "8px",
        },
      },
    },

    MuiSwitch: {
      styleOverrides: {
        root: {
          margin: "8px 0px",
          height: "24px",
          padding: "0px",

          "& .MuiSwitch-thumb": {
            width: "100%",
            height: "20px",
          },
          "& .MuiSwitch-switchBase": {
            padding: "0px",
          },
        },

        switchBase: {
          color: "white",
          padding: "0px",
          left: "2px",
          top: "2px",
          width: "20px",

          "&.Mui-checked": {
            color: "white",

            "& .MuiSwitch-track": {
              opacity: "100%",
            },
          },
        },

        track: {
          width: "44px",
          borderRadius: "100px",
          backgroundColor: "#EAEBEC",
          boxShadow: "0px 1px 5px 0px rgba(0, 0, 0, 0.10) inset",
          opacity: "100% !important",
        },

        thumb: {
          boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.10)",
        },

        edgeEnd: {
          borderRadius: "100px",
        },
      },
    },

    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          marginLeft: "0px",
        },
        label: {
          fontWeight: "500",
        },
      },
    },

    MuiAutocomplete: {
      styleOverrides: {
        tag: {
          marginTop: "13px",
          fontWeight: 500,
        },
        inputFocused: {
          fontWeight: 500,
        },
      },
    },
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiTextField: {
      defaultProps: {
        autoComplete: "off",
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: "#198282",
          fontWeight: 600,
          textDecoration: "none",
          "&:hover": {
            textDecoration: "none",
          },
        },
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1100, // This is the only one that's being overridden
      xl: 1536,
    },
  },
  palette: {
    background: {
      default: "#FFF",
    },
    primary: {
      main: "#198282",
      light: "#C8DBD7",
      dark: "#3C5556",
    },
    secondary: {
      main: "#EF7B77",
      light: "#FCDBCD",
      dark: "#C14743",
    },
    error: {
      main: "#C14743",
      light: "#FCDBCD",
      dark: "#C14743",
    },
    text: {
      primary: "#3D3D3D",
      secondary: "#6B6E7B",
      tertiary: "#8E9598",
    },
    border: "#D4D4D4",
  },
  mixins: {
    toolbar: {
      minHeight: "50px",
    },
  },
});

export default baseTheme;
