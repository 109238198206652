import React, { ReactNode, useState } from "react";
import { Box, Button, ButtonGroup, Grid } from "@mui/material";
import MaybeTransition from "./MaybeTransition";
import useIsMobile from "../hooks/useIsMobile";

export enum Panel {
  Left,
  Right,
}

const StyledHeader = ({ children }: { children: ReactNode }) => {
  return (
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      sx={{
        width: "100%",
        display: "inline-flex",
        padding: `20px 20px 0`,
      }}
    >
      {children}
    </Box>
  );
};

type PanelProps = {
  content: React.ReactNode;
  title?: React.ReactNode | string;
  subtitle?: React.ReactNode | string;
  switchContent?: React.ReactNode | string;
  sx?: React.CSSProperties;
};

type TwoPanelGridProps = {
  leftPanel: PanelProps;
  rightPanel?: PanelProps;
  defaultPanel?: Panel;
  onChangePanel?: (panel: Panel) => void;
  headerAction?: ReactNode;
};
export default ({
  leftPanel,
  rightPanel,
  onChangePanel,
  headerAction,
  defaultPanel = Panel.Left,
}: TwoPanelGridProps) => {
  const isMobile = useIsMobile();
  const [panel, setPanel] = useState<Panel>(defaultPanel);
  const changePanel = (panel: Panel) => {
    setPanel(panel);
    onChangePanel && onChangePanel(panel);
  };
  return (
    <Box
      display="flex"
      flexDirection="column"
      sx={{
        height: isMobile ? "calc(100vh - 50px)" : "100vh",
      }}
    >
      {isMobile && (
        <StyledHeader>
          <Box display="flex" flexDirection="column" width="100%">
            {panel === Panel.Left ? leftPanel.title : rightPanel?.title}
            {panel === Panel.Left ? leftPanel.subtitle : rightPanel?.subtitle}
          </Box>
          {leftPanel?.switchContent && rightPanel?.switchContent && (
            <Box>
              <ButtonGroup
                sx={{ boxShadow: "0px 0px 2px 0px rgba(0, 0, 0, 0.25) inset" }}
              >
                <Button
                  sx={{
                    marginRight: "10px",
                    height: "40px",
                    width: "40px",
                    padding: 0,
                  }}
                  variant={
                    panel === Panel.Left ? "buttonGroupSelected" : "buttonGroup"
                  }
                  onClick={() => changePanel(Panel.Left)}
                >
                  {leftPanel.switchContent}
                </Button>
                <Button
                  sx={{
                    height: "40px",
                    width: "40px",
                    padding: 0,
                  }}
                  variant={
                    panel === Panel.Right
                      ? "buttonGroupSelected"
                      : "buttonGroup"
                  }
                  onClick={() => changePanel(Panel.Right)}
                >
                  {rightPanel?.switchContent}
                </Button>
              </ButtonGroup>
            </Box>
          )}
        </StyledHeader>
      )}
      <Grid
        container
        spacing={0}
        sx={{
          height: "100%",
        }}
      >
        <MaybeTransition visible={panel === Panel.Left}>
          <Grid
            item
            xs={12}
            md={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              padding: isMobile ? "0" : "32px 64px 0",
              borderRight: isMobile ? undefined : "1px solid #EAECF0",
            }}
          >
            {!isMobile && (
              <StyledHeader>
                <Box display="flex" flexDirection="column" width="100%" sx={{}}>
                  {leftPanel.title}
                  {leftPanel.subtitle}
                </Box>
                {headerAction}
              </StyledHeader>
            )}
            {leftPanel.content}
          </Grid>
        </MaybeTransition>
        <MaybeTransition visible={panel === Panel.Right}>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              ...rightPanel?.sx,
            }}
          >
            {rightPanel && (
              <>
                {!isMobile && (rightPanel.title || rightPanel.subtitle) && (
                  <StyledHeader>
                    <Box display="flex" flexDirection="column" width="100%">
                      {rightPanel.title}
                      {rightPanel.subtitle}
                    </Box>
                  </StyledHeader>
                )}
                {rightPanel.content}
              </>
            )}
          </Grid>
        </MaybeTransition>
      </Grid>
    </Box>
  );
};
