import { ReactNode } from "react";
import { instructionPage, noImageInstructionPage } from "./InstructionStepper";

export abstract class ExportedCalendar {
  title: string;
  name: string;
  steps?: Array<ReactNode>;

  constructor(name: string, title: string, steps?: Array<ReactNode>) {
    this.name = name;
    this.title = title;
    this.steps = steps;
  }

  abstract getAddCalendarUrlAndButton(
    calendarUrl: string,
  ): [string, string] | null;
}

class Google extends ExportedCalendar {
  constructor() {
    super("google", "Google Calendar", [
      instructionPage(
        "/assets/images/calendars/exports/google/export-step1.png",
        "vertical",
        [
          {
            number: 1,
            content: (
              <>
                Log in to Google Calendar and navigate to <b>Settings</b> in the
                upper right.
              </>
            ),
          },
        ],
      ),
      instructionPage(
        "/assets/images/calendars/exports/google/export-step2.png",
        "vertical",
        [
          {
            number: 2,
            content: (
              <>
                Click on <b>Add calendar</b> in the sidebar.
              </>
            ),
          },
        ],
      ),
      instructionPage(
        "/assets/images/calendars/exports/google/export-step3.png",
        "vertical",
        [
          {
            number: 3,
            content: (
              <>
                Click on <b>From URL</b> in the sidebar.
              </>
            ),
          },
          {
            number: 4,

            content: (
              <>
                Paste in the Faye calendar URL included below and click
                <b> Add calendar</b>.
              </>
            ),
          },
        ],
      ),
    ]);
  }
  getAddCalendarUrlAndButton(calendarUrl: string): [string, string] | null {
    // https://stackoverflow.com/questions/74709014/pre-populate-google-calendar-subscribe-by-url
    const url = `https://calendar.google.com/calendar/r?cid=${encodeURIComponent(
      calendarUrl.replace("https://", "webcal://"),
    )}`;
    return [url, "Export to Google"];
  }
}

export const GoogleExporter = new Google();

class ICSUrlExport extends ExportedCalendar {
  getAddCalendarUrlAndButton() {
    return null;
  }
}
export const AppleExporter = new ICSUrlExport("apple", "Apple Calendar", [
  instructionPage(
    "/assets/images/calendars/exports/apple/export-step1.png",
    "horizontal",
    [
      {
        number: 1,
        content: (
          <>
            In Apple Calendar, go to <b>File</b> {">"}{" "}
            <b>New Calendar Subscription...</b>
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/exports/apple/export-step2.png",
    "vertical",
    [
      {
        number: 2,
        content: (
          <>
            Copy the <b>Faye calendar URL</b> below and paste it into the{" "}
            <b>Calendar URL</b> field.
          </>
        ),
      },
      {
        number: 3,
        content: (
          <>
            Click <b>Subscribe</b>.
          </>
        ),
      },
    ],
  ),
]);
export const SkylightExporter = new ICSUrlExport(
  "skylight",
  "Skylight Calendar",
  [
    instructionPage(
      "/assets/images/calendars/exports/skylight/export-step1.png",
      "horizontal",
      [
        {
          number: 1,
          content: (
            <>
              Navigate to the{" "}
              <a
                target="_blank"
                href="https://app.ourskylight.com/"
                style={{
                  color: "#198282",
                  textDecoration: "none",
                  fontWeight: "700",
                }}
              >
                Skylight app{" "}
              </a>
              and select your device.
            </>
          ),
        },
        {
          number: 2,
          content: (
            <>
              Click on the <b>Synced Cals</b> tab.
            </>
          ),
        },
        {
          number: 3,
          content: (
            <>
              Select <b>Sync a New Calendar</b>.
            </>
          ),
        },
      ],
    ),
    instructionPage(
      "/assets/images/calendars/exports/skylight/export-step2.png",
      "horizontal",
      [
        {
          number: 4,
          content: (
            <>
              Select the <b>Other</b> option.
            </>
          ),
        },
      ],
    ),
    instructionPage(
      "/assets/images/calendars/exports/skylight/export-step3.png",
      "horizontal",
      [
        {
          number: 5,
          content: (
            <>
              Paste in the below URL and select <b>Sync</b>.
            </>
          ),
        },
      ],
    ),
  ],
);
export const CoziExporter = new ICSUrlExport("cozi", "Cozi", [
  instructionPage(
    "/assets/images/calendars/exports/cozi/cozi-step-1.png",
    "horizontal",
    [
      {
        number: 1,
        content: (
          <>
            Log in to your Cozi account via the mobile app or at{" "}
            <a
              target="_blank"
              href="https://my.cozi.com"
              style={{
                color: "#198282",
                textDecoration: "none",
                fontWeight: "700",
              }}
            >
              my.cozi.com
            </a>
            .
          </>
        ),
      },
      {
        number: 2,
        content: (
          <>
            Navigate to <b>Settings</b> in the sidebar.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/exports/cozi/cozi-step-2.png",
    "horizontal",
    [
      {
        number: 3,
        content: (
          <>
            Select <b>Connected Calendars</b>.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/exports/cozi/cozi-step-3.png",
    "horizontal",
    [
      {
        number: 4,
        content: (
          <>
            Select <b>ADD A CALENDAR FROM A URL</b>.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/exports/cozi/cozi-step-4.png",
    "horizontal",
    [
      {
        number: 5,
        content: (
          <>
            Paste in the <b>Faye calendar URL</b> below.
          </>
        ),
      },
    ],
  ),
]);
export const GenericICSUrlExporter = new ICSUrlExport("other", "Other", [
  noImageInstructionPage([
    {
      content: (
        <>
          Most calendars allow for importing or subscribing to external
          calendars. If you would like to import your Faye calendar into a
          calendar not listed, try these steps or reach out for help at{" "}
          <a target="_blank" href="mailto:support@findfaye.com">
            support@findfaye.com
          </a>
          .
        </>
      ),
    },
    {
      number: 1,
      content: (
        <>
          In your calendar, look for an option to <b>import</b>,{" "}
          <b>subscribe</b> or <b>connect</b> to a calendar.
        </>
      ),
    },
    {
      number: 2,
      content: (
        <>If the calendar asks for a ICS path or URL, paste in the below URL.</>
      ),
    },
  ]),
]);
export const OutlookExporter = new ICSUrlExport("outlook", "Outlook", [
  instructionPage(
    "/assets/images/calendars/exports/outlook/outlook-step-1.png",
    "horizontal",
    [
      {
        number: 1,
        content: (
          <>
            In{" "}
            <a
              target="_blank"
              href="https://outlook.live.com/calendar"
              style={{
                color: "#198282",
                textDecoration: "none",
                fontWeight: "700",
              }}
            >
              Outlook Calendar
            </a>
            , click on <b>Add Calendar</b> in the sidebar.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/exports/outlook/outlook-step-2.png",
    "horizontal",
    [
      {
        number: 2,
        content: (
          <>
            Click on <b>Subscribe from web</b> in the sidebar.
          </>
        ),
      },
      {
        number: 3,
        content: (
          <>
            Copy the Faye calendar URL below these instructions and paste it
            into the input on Outlook.
          </>
        ),
      },
      {
        number: 4,
        content: (
          <>
            Click <b>Import</b>.
          </>
        ),
      },
    ],
  ),
]);
export const YahooExporter = new ICSUrlExport("yahoo", "Yahoo", [
  instructionPage(
    "/assets/images/calendars/exports/yahoo/yahoo-step-1.png",
    "vertical",
    [
      {
        number: 1,
        content: (
          <>
            Open{" "}
            <a
              target="_blank"
              href="https://calendar.yahoo.com/"
              style={{
                color: "#198282",
                textDecoration: "none",
                fontWeight: "700",
              }}
            >
              Yahoo Calendar
            </a>{" "}
            and click <b>Actions</b> in the top bar.
          </>
        ),
      },
      {
        number: 2,
        content: (
          <>
            Select <b>Follow Other Calendars</b> from the dropdown.
          </>
        ),
      },
    ],
  ),
  instructionPage(
    "/assets/images/calendars/exports/yahoo/yahoo-step-2.png",
    "horizontal",
    [
      {
        number: 3,
        content: <>Add a title such as "Faye Family Calendar".</>,
      },
      {
        number: 4,
        content: (
          <>
            Copy the <b>Faye calendar URL</b> below, and paste it into the{" "}
            <b>Calendar URL</b> field.
          </>
        ),
      },
      {
        number: 5,
        content: (
          <>
            Adjust the other settings as you prefer and then click <b>Save</b>.
          </>
        ),
      },
    ],
  ),
]);

export const EXPORT_CALENDARS = [
  GoogleExporter,
  OutlookExporter,
  SkylightExporter,
  CoziExporter,
  YahooExporter,
  AppleExporter,
  GenericICSUrlExporter,
];
