import { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Typography, Divider } from "@mui/material";
import auth from "components/nux/auth";
import Checkbox from "components/common/Checkbox";
import { useAcceptMemberPlan } from "../../..//services/nux";
import { getStepPath } from "components/nux/utils";
import NuxContentLayout from "components/nux/NuxContentLayout";
import useIsMobile from "../../..//components/hooks/useIsMobile";
import { USER_NUX_TYPE } from "components/nux/constants";
import { useGetMemberDetails } from "services/member";
import CurrentUserAvatar from "components/common/CurrentUserAvatar";
import { FileCheck, Lock } from "lucide-react";
import MemberPayment from "components/billing/MemberPayment";
import { TOS_VERSION } from "components/context/CheckTerms";
import { NuxDetailsContext } from "components/context/AdvisorNuxContext";

// const formateStartDate = (dateString: string): string => {
//   let date = new Date(dateString);
//   let options: Intl.DateTimeFormatOptions = {
//     year: "numeric",
//     month: "long",
//     day: "numeric",
//   };
//   return date.toLocaleDateString("en-US", options);
// };
export default () => {
  const memberPaymentFormRef = useRef({
    submitForm: async () => Promise<void>,
  });
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [loading, setLoading] = useState(false);
  const nuxDetails = useContext(NuxDetailsContext);
  const [serviceDetails, setServiceDetails] = useState<string>("");
  const [hasAcceptedToS, setHasAcceptedToS] = useState(false);
  const [hasAcceptedBilling, setHasAcceptedBilling] = useState(false);
  const [memberFormError, setMemberFormError] = useState<string | null>(null);

  const { request, loading: acceptLoading } = useAcceptMemberPlan((r) => {
    if (r?.nuxDetails?.stepName) {
      const path = getStepPath(r.nuxDetails.stepName, USER_NUX_TYPE.MEMBER);
      navigate(path);
    }
  });

  const {
    request: getMemberRequest,
    data,
    loading: memberLoading,
  } = useGetMemberDetails();
  useEffect(() => {
    getMemberRequest();
  }, []);
  const alreadyHasBilling = !!data?.family?.billingInfo?.stripeCustomerId;
  useEffect(() => {
    try {
      const nuxState = JSON.parse(nuxDetails?.state);
      setServiceDetails(nuxState.serviceDetails);
    } catch (e) {
      setServiceDetails("");
    }
  }, [nuxDetails]);

  const onContinue = async () => {
    if (!hasAcceptedToS || !hasAcceptedBilling || !data) return;
    if (!memberPaymentFormRef.current) {
      return;
    }
    setMemberFormError(null);
    if (!alreadyHasBilling) {
      setLoading(true);
      try {
        await memberPaymentFormRef.current.submitForm();
      } catch (e) {
        if (e instanceof Error) {
          setMemberFormError(e.message);
        }
        setLoading(false);
        return;
      }
      setLoading(false);
    }
    request({
      token: auth.getAuthData(),
      termsOfServiceVersion: TOS_VERSION,
    });
  };
  let firstMonthCost = data?.family?.billingInfo?.product?.amountCents;
  if (data?.family?.billingInfo?.introCoupon?.id) {
    if (data?.family?.billingInfo?.introCoupon.amountOffCents) {
      firstMonthCost -= data?.family?.billingInfo?.introCoupon.amountOffCents;
    } else {
      firstMonthCost -=
        firstMonthCost *
        (data?.family?.billingInfo?.introCoupon.percentOff / 100);
    }
  }
  const overallLoading = memberLoading || loading || acceptLoading;
  return (
    <NuxContentLayout>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "48px",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "24px",
          }}
        >
          <Typography variant="title">Plan details</Typography>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography variant="h3Serif">Plan agreement</Typography>
          <Box
            sx={{
              display: "flex",
              gap: "16px",
              flexDirection: isMobile ? "column" : "row",
            }}
          >
            <Box
              sx={(theme) => ({
                width: isMobile ? "100%" : "50%",
                display: "flex",
                gap: "16px",
                padding: "16px",
                border: `1px solid ${theme.palette.border}`,
                borderRadius: "12px",
              })}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <CurrentUserAvatar
                  user={data?.primaryAdvisor}
                  size={60}
                  publicLink={true}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Typography variant="h3Serif">
                  {data?.primaryAdvisor?.displayName || ""}
                </Typography>
                <Typography variant="bodySmallHeavy" color="text.secondary">
                  Faye Advisor
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: isMobile ? "100%" : "50%",
                borderRadius: "12px",
                backgroundColor: "#F9FAFA",
                padding: "24px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  flexDirection: "column",
                  justifyContent: "space-between",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4">Monthly rate</Typography>
                  <Typography variant="h4" sx={{ fontSize: "20px" }}>
                    ${(firstMonthCost / 100).toFixed(2)}
                  </Typography>
                </Box>
                {!!data?.family?.billingInfo?.introCoupon?.id && (
                  <Box
                    sx={{
                      marginTop: isMobile ? "8px" : "16px",
                      display: "flex",
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="bodyHeavy"
                      color="#6B6E7B"
                      sx={{ fontWeight: 500 }}
                    >
                      After trial ends
                    </Typography>
                    <Typography
                      variant="bodyHeavy"
                      color="#6B6E7B"
                      sx={{ fontWeight: 500 }}
                    >
                      $
                      {(
                        data?.family?.billingInfo?.product?.amountCents / 100
                      ).toFixed(2)}
                      /mo
                    </Typography>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
          <Typography variant="h3Serif">
            Notes from {data?.primaryAdvisor?.firstName || ""}
          </Typography>
          <Typography variant="body">{serviceDetails}</Typography>
          <Button
            variant="text"
            component="a"
            href="https://www.findfaye.com/client-terms"
            target="_blank"
            rel="noopener noreferrer"
            sx={{ height: "28px", alignSelf: "flex-start" }}
          >
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px" }}>
              <FileCheck size={24} color="#198282" />
              Terms of service
            </Box>
          </Button>
          {/*<Typography variant="body">*/}
          {/*  Service begins {formateStartDate(data?.family?.startDate)}*/}
          {/*</Typography>*/}
        </Box>
        <Divider></Divider>
        {!alreadyHasBilling && (
          <Box sx={{ display: "flex", flexDirection: "column", gap: "16px" }}>
            <Box sx={{ display: "flex", gap: "8px", alignItems: "center" }}>
              <Typography variant="h3Serif">Payment information</Typography>
              <Lock size={16} color="#8E9598" />
            </Box>
            <Typography variant="body">
              We’ll charge your card today, but you won’t be billed again until
              one month after the onboarding call with your Advisor. You can
              cancel your service at any time.
            </Typography>
            {data?.family && data?.member && (
              <Box sx={{}}>
                <MemberPayment
                  family={data?.family}
                  member={data?.member}
                  ref={memberPaymentFormRef}
                />
              </Box>
            )}
            <Box>
              {memberFormError && (
                <Typography variant="body" color="error">
                  {memberFormError}
                </Typography>
              )}
            </Box>
          </Box>
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <label style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={hasAcceptedToS}
              onChange={() => {
                setHasAcceptedToS(!hasAcceptedToS);
              }}
            />
            <Typography variant="body">
              I agree to the terms of service.
            </Typography>
          </label>
          <label style={{ display: "flex", alignItems: "center" }}>
            <Checkbox
              checked={hasAcceptedBilling}
              onChange={() => {
                setHasAcceptedBilling(!hasAcceptedBilling);
              }}
            />
            <Typography variant="body">
              I agree to be auto-billed monthly and can cancel my service at any
              time.
            </Typography>
          </label>
        </Box>
        <Box sx={{ alignSelf: "flex-end", width: isMobile ? "100%" : "auto" }}>
          <Button
            sx={{ width: "100%" }}
            onClick={onContinue}
            disabled={overallLoading || !hasAcceptedBilling || !hasAcceptedToS}
          >
            Continue
          </Button>
        </Box>
      </Box>
    </NuxContentLayout>
  );
};
