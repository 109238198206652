import * as React from "react";
import { useEffect, useState } from "react";
import {
  Button,
  Dialog,
  Link,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import AuthService from "services/auth";
import { refreshToken } from "services/api";

import { AccountType } from "protogen/common_pb";

export const TOS_VERSION = 1;

type Props = {
  accountType: AccountType;
  onAccept?: () => void;
  tosContent: React.ReactNode;
};
const TOSModal = ({ onAccept, accountType, tosContent }: Props) => {
  const { request } = AuthService.useAcceptTermsOfService();
  const [open, setOpen] = React.useState(true);
  const onClick = async () => {
    await request({
      accountType,
      termsOfServiceVersion: TOS_VERSION,
    });
    await refreshToken();
    setOpen(false);
    onAccept && onAccept();
  };
  return (
    <Dialog
      open={open}
      fullWidth={true}
      maxWidth="sm"
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      // Very inelegant solution. It's quite likely at first login a user will see prompts
      // to accept terms of service and notification permissions. The TOS are more urgent
      // so let's prioritize them.
      // A more elegant solution would handle modal prioritization and only rendering one at a time
      // rather than stacking them as we do here, however I can't figure out a way to do that in
      // a generalized way so this seems like the best approach.
      sx={{ zIndex: 1301 }}
    >
      <DialogTitle id="alert-dialog-title">Welcome to Faye!</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {tosContent}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClick}>Accept</Button>
      </DialogActions>
    </Dialog>
  );
};

const AdvisorContent = () => (
  <>
    Before creating an account, please read and accept our binding&nbsp;
    <Link target="_blank" href={"https://findfaye.com/advisor-terms"}>
      Advisor Terms of Service
    </Link>
    &nbsp;and&nbsp;
    <Link target="_blank" href={"https://findfaye.com/privacy-policy"}>
      Privacy Policy
    </Link>
    . Thank you. Excited to have you on board!
  </>
);

const MemberContent = () => (
  <>
    Before creating an account, please read and accept our binding&nbsp;
    <Link target="_blank" href={"https://findfaye.com/client-terms"}>
      Client Terms of Service
    </Link>
    &nbsp;and&nbsp;
    <Link target="_blank" href={"https://findfaye.com/privacy-policy"}>
      Privacy Policy
    </Link>
    . Thank you.
  </>
);

export default ({
  accountType,
  children,
}: {
  accountType: AccountType;
  children?: React.ReactNode;
}) => {
  const currentUser = AuthService.getCurrentUser();
  const [doubleChecked, setDoubleChecked] = useState(false);

  let tosContent: React.ReactNode;
  if (accountType === AccountType.ADVISOR) {
    tosContent = <AdvisorContent />;
  } else if (accountType === AccountType.MEMBER) {
    tosContent = <MemberContent />;
  } else {
    throw new Error("Invalid account type");
  }

  // Before prompting for TOS, force a refresh of the token to ensure we have the latest state of TOS.
  useEffect(() => {
    const check = async () => {
      if (
        currentUser &&
        !currentUser.hasAcceptedTermsOfService &&
        !doubleChecked
      ) {
        await refreshToken();
        setDoubleChecked(true);
      }
    };
    check();
  }, []);
  return (
    <>
      {children}
      {currentUser &&
        !currentUser.hasAcceptedTermsOfService &&
        doubleChecked && (
          <TOSModal accountType={accountType} tosContent={tosContent} />
        )}
    </>
  );
};
