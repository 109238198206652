import useProtoMethod from "./useProtoMethod";
import {
  CreateFamilyRequest,
  CreateFamilyResponse,
  FetchAdvisorNotificationStatusesRequest,
  FetchAdvisorNotificationStatusesResponse,
  FetchAdvisorProfileRequest,
  FetchAdvisorProfileResponse,
  GetFamilyActivityRequest,
  GetFamilyActivityResponse,
  GetFamilyDetailsRequest,
  GetFamilyDetailsResponse,
  ListActivitySubscriptionsRequest,
  ListActivitySubscriptionsResponse,
  ListAdvisorFamiliesRequest,
  ListAdvisorFamiliesResponse,
  ListAllAdvisorsRequest,
  ListAllAdvisorsResponse,
  NotificationRegistrationRequest,
  NotificationRegistrationResponse,
  UpdateAdvisorRequest,
  UpdateAdvisorResponse,
  UpdateActivitySubscriptionRequest,
  UpdateActivitySubscriptionResponse,
  UpdateFamilyRequest,
  UpdateFamilyResponse,
  GetConversationFeedResponse,
  GetConversationFeedRequest,
  FetchPhoneInboxResponse,
  FetchPhoneInboxRequest,
  StartExternalConversationResponse,
  StartExternalConversationRequest,
  GetTextMessageEntryRequest,
  GetTextMessageEntryResponse,
  UpdateExternalContactReponse,
  UpdateExternalContactRequest,
  FetchInvoiceHistoryRequest,
  FetchInvoiceHistoryResponse,
} from "protogen/advisors_service_pb";

const useListAdvisorFamilies = (
  callback?: (r: ListAdvisorFamiliesResponse) => void,
) => {
  return useProtoMethod<
    ListAdvisorFamiliesRequest,
    ListAdvisorFamiliesResponse
  >("ListAdvisorFamilies", ListAdvisorFamiliesResponse, { callback });
};

const useFetchAdvisorNotificationStatuses = (
  callback?: (r: FetchAdvisorNotificationStatusesResponse) => void,
) => {
  return useProtoMethod<
    FetchAdvisorNotificationStatusesRequest,
    FetchAdvisorNotificationStatusesResponse
  >(
    "FetchAdvisorNotificationStatuses",
    FetchAdvisorNotificationStatusesResponse,
    {
      callback,
    },
  );
};

const useListAllAdvisors = (
  callback?: (r: ListAllAdvisorsResponse) => void,
) => {
  return useProtoMethod<ListAllAdvisorsRequest, ListAllAdvisorsResponse>(
    "ListAllAdvisors",
    ListAllAdvisorsResponse,
    { callback },
  );
};

const useCreateFamily = (callback?: (r: CreateFamilyResponse) => void) => {
  return useProtoMethod<CreateFamilyRequest, CreateFamilyResponse>(
    "CreateFamily",
    CreateFamilyResponse,
    { callback },
  );
};

const useUpdateFamily = (callback?: (r: UpdateFamilyResponse) => void) => {
  return useProtoMethod<UpdateFamilyRequest, UpdateFamilyResponse>(
    "UpdateFamily",
    UpdateFamilyResponse,
    { callback },
  );
};

const useGetFamilyDetails = (
  callback?: (r: GetFamilyDetailsResponse) => void,
) => {
  return useProtoMethod<GetFamilyDetailsRequest, GetFamilyDetailsResponse>(
    "GetFamilyDetails",
    GetFamilyDetailsResponse,
    { callback },
  );
};

const useGetFamilyActivity = (
  callback?: (r: GetFamilyActivityResponse) => void,
) => {
  return useProtoMethod<GetFamilyActivityRequest, GetFamilyActivityResponse>(
    "GetFamilyActivity",
    GetFamilyActivityResponse,
    { callback },
  );
};

const useGetTextMessageEntry = (
  callback?: (r: GetTextMessageEntryResponse) => void,
) => {
  return useProtoMethod<
    GetTextMessageEntryRequest,
    GetTextMessageEntryResponse
  >("GetTextMessageEntry", GetTextMessageEntryResponse, {
    callback,
  });
};

const useNotificationRegistration = () => {
  return useProtoMethod<
    NotificationRegistrationRequest,
    NotificationRegistrationResponse
  >("NotificationRegistration", NotificationRegistrationResponse);
};

const useListActivitySubscriptions = () => {
  return useProtoMethod<
    ListActivitySubscriptionsRequest,
    ListActivitySubscriptionsResponse
  >("ListActivitySubscriptions", ListActivitySubscriptionsResponse);
};

const useUpdateAdvisor = (callback?: (r: UpdateAdvisorResponse) => void) => {
  return useProtoMethod<UpdateAdvisorRequest, UpdateAdvisorResponse>(
    "UpdateAdvisor",
    UpdateAdvisorResponse,
    { callback },
  );
};

const useUpdateActivitySubscription = (
  callback?: (r: UpdateActivitySubscriptionResponse) => void,
) => {
  return useProtoMethod<
    UpdateActivitySubscriptionRequest,
    UpdateActivitySubscriptionResponse
  >("UpdateActivitySubscription", UpdateActivitySubscriptionResponse, {
    callback,
  });
};

const useFetchAdvisorProfile = (
  callback?: (r: FetchAdvisorProfileResponse) => void,
) => {
  return useProtoMethod<
    FetchAdvisorProfileRequest,
    FetchAdvisorProfileResponse
  >("FetchAdvisorProfile", FetchAdvisorProfileResponse, { callback });
};

const useGetConversationFeed = (
  callback?: (r: GetConversationFeedResponse) => void,
) => {
  return useProtoMethod<
    GetConversationFeedRequest,
    GetConversationFeedResponse
  >("GetConversationFeed", GetConversationFeedResponse, { callback });
};

const useFetchPhoneInbox = (
  callback?: (r: FetchPhoneInboxResponse) => void,
) => {
  return useProtoMethod<FetchPhoneInboxRequest, FetchPhoneInboxResponse>(
    "FetchPhoneInbox",
    FetchPhoneInboxResponse,
    { callback },
  );
};

const useStartExternalConversation = (
  callback?: (r: StartExternalConversationResponse) => void,
) => {
  return useProtoMethod<
    StartExternalConversationRequest,
    StartExternalConversationResponse
  >("StartExternalConversation", StartExternalConversationResponse, {
    callback,
  });
};

const useUpdateExternalContact = (
  callback?: (r: UpdateExternalContactReponse) => void,
) => {
  return useProtoMethod<
    UpdateExternalContactRequest,
    UpdateExternalContactReponse
  >("UpdateExternalContact", UpdateExternalContactReponse, {
    callback,
  });
};

const useFetchInvoiceHistory = (
  callback?: (r: FetchInvoiceHistoryResponse) => void,
) => {
  return useProtoMethod<
    FetchInvoiceHistoryRequest,
    FetchInvoiceHistoryResponse
  >("FetchInvoiceHistory", FetchInvoiceHistoryResponse, {
    callback,
  });
};

export {
  useListAdvisorFamilies,
  useFetchAdvisorNotificationStatuses,
  useListAllAdvisors,
  useCreateFamily,
  useUpdateFamily,
  useGetFamilyDetails,
  useGetFamilyActivity,
  useGetTextMessageEntry,
  useNotificationRegistration,
  useListActivitySubscriptions,
  useUpdateActivitySubscription,
  useFetchAdvisorProfile,
  useUpdateAdvisor,
  useGetConversationFeed,
  useFetchPhoneInbox,
  useStartExternalConversation,
  useUpdateExternalContact,
  useFetchInvoiceHistory,
};
